import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./index.module.css";
import { BackArrow } from "../../icons";
import Done from "../../icons/done-icon.png";
import Wrapper from "../../components/UI/wrapper";
import Loading from "../../components/loading";
import Nominations from "../../api/nominations";
import Regions from "../../api/regions";
import personal from "../../api/personal";
import "./index.css";
import Schools from "../../api/schools";
import personalValidation from "../../api/personalValidation";
import SearchableDropdown from "../../components/searchableDropdown";
import SearchableDropdownCustom from "../../components/searchableDropdownCustom";
import AgreementPopup from "../../components/agreementPopup";
import InfoPopup from "../../components/infoPopup";
import FinAndSerialOld from "../../assets/images/documents/Fin_and_serial_old.jpeg";
import FinNew from "../../assets/images/documents/Fin_new.png";
import SerialNew from "../../assets/images/documents/serial_new.png";
import BirthCertImg from "../../assets/images/documents/birth_cert.png";
import searchImg from "../../assets/images/linear.svg";
import { DatePicker, Form, Input, Select } from "antd";
import instance from "../../api";
import dayjs from "dayjs";
import { getAllCities } from "../../api/cities";

const dateFormat = "YYYY-MM-DD";

const prefixes = [
  {
    value: "050",
    label: "050",
  },
  {
    value: "051",
    label: "051",
  },
  {
    value: "010",
    label: "010",
  },

  {
    value: "055",
    label: "055",
  },
  {
    value: "099",
    label: "099",
  },
  {
    value: "070",
    label: "070",
  },
  {
    value: "077",
    label: "077",
  },
  {
    value: "060",
    label: "060",
  },
];

const PersonalRegistrationTest = () => {
  const [form2] = Form.useForm();
  const [page, setPage] = useState(1);
  const [isSearched, setIsSearched] = useState(false)
  const [imesUser, setImesUser] = useState(null);
  const [studentFromAsan, setStudentFromAsan] = useState(null);
  const [parentFromAsan, setParentFromAsan] = useState(null);
  const [awardsVal, setAwardsVal] = useState([]);
  const [nameVal, setNameVal] = useState(null);
  const [surnameVal, setSurnameVal] = useState(null);
  const [patronymicVal, setPatronymicVal] = useState(null);
  const [dateVal, setDateVal] = useState(null);
  const [finRadio, setFinRadio] = useState(1);
  const [serialRadio, setSerialRadio] = useState(1);
  const [finVal, setFinVal] = useState(null);
  const [serialVal, setSerialVal] = useState(null);
  const [birthCert, setBirthCert] = useState(null);
  const [regAddress, setRegAddress] = useState(null);
  const [factAddress, setFactAddress] = useState(null);
  const [city, setCity] = useState(null);
  const [image, setImage] = useState(null);
  const [imageLink, setImageLink] = useState(null);
  const [asanImage, setAsanImage] = useState(null);
  const [awardVal, setAwardVal] = useState(null);
  const [specialSchool, setSpecialSchool] = useState(null);
  const [parentsName, setParentsName] = useState(null);
  const [parentsSurname, setParentsSurname] = useState(null);
  const [parentsPatronymic, setParentsPatronymic] = useState(null);
  const [parentsFin, setParentsFin] = useState(null);
  const [parentsSerialRadio, setParentsSerialRadio] = useState(1);
  const [parentsSerial, setParentsSerial] = useState(null);
  const [parentsMobile, setParentsMobile] = useState({
    first: { prefix: prefixes[0].value, number: "" },
    second: { prefix: prefixes[0].value, number: "" },
  });
  const [parentsEmail, setParentsEmail] = useState(null);
  const [rules, setRules] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [agreementPopup, setAgreementPopup] = useState(false);
  const [finPopup, setFinPopup] = useState(false);
  const [serialPopup, setSerialPopup] = useState(false);
  const [imagePopup, setImagePopup] = useState(false);
  const [birthCertPopup, setBirthCertPopup] = useState(false);
  const [genderRadio, setGenderRadio] = useState("MALE");
  const [imageType, setImageType] = useState(2);
  const [code, setCode] = useState(null);
  const [secondPageData, setSecondPageData] = useState(null)
  const [citySecond, setCitySecond] = useState(null)
  const [school, setSchool] = useState(null);
  const [selectedSchoolType, setSelectedSchoolType] = useState(null);
  const [nomination, setNomination] = useState(null);
  const [artSchool, setArtSchool] = useState(2);
  const [artEducation, setArtEducation] = useState(null)
  const [artSchoolDetails, setArtSchoolDetails] = useState(null);

  /*Loading */
  const [loading, setLoading] = useState(false);

  /*Errors*/
  const [imageError, setImageError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [ageError, setAgeError] = useState(null);
  const [regAddressError, setRegAddressError] = useState(null);
  const [factAddressError, setFactAddressError] = useState(null);
  const [finError, setFinError] = useState(null);
  const [birthCertError, setBirthCertError] = useState(null);
  const [serialError, setSerialError] = useState(null);
  const [cityError, setCityError] = useState(null);
  const [schoolError, setSchoolError] = useState(null);
  const [nominationError, setNominationError] = useState(null);
  const [parentsNameError, setParentsNameError] = useState(null);
  const [parentsFinError, setParentsFinError] = useState(null);
  const [parentsSerialError, setParentsSerialError] = useState(null);
  const [parentsMobileError, setParentsMobileError] = useState({
    first: "",
    second: "",
  });
  const [codeError, setCodeError] = useState(null);

  const [parentsEmailError, setParentsEmailError] = useState("");
  const [schoolTypeError, setSchoolTypeError] = useState(null);
  const [postResponse, setPostResponse] = useState(null);
  const [firstPageError, setFirstPageError] = useState(null);
  const [secondPageError, setSecondPageError] = useState(null);
  const [thirdPageError, setThirdPageError] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [searchError, setSearchError] = useState(false);
  /* api variables */

  const [nominationsSelect, setNominationsSelect] = useState([]);
  const [regionsSelect, setRegionsSelect] = useState([]);
  const [artSchoolsSelect, setArtSchoolsSelect] = useState([]);
  const [schoolTypes, setSchoolTypes] = useState([]);
  const [educationalSchools, setEducationalSchools] = useState([]);
  const [citiesSelect, setCitiesSelect] = useState([]);

  const handleAwardAdd = () => {
    if (awardVal?.trim()?.length > 0) {
      setAwardsVal((prev) => [...prev, awardVal]);
      setAwardVal("");
    }
  };
  const handleAwardChange = (onChangeValue, i) => {
    const inputdata = [...awardsVal];
    inputdata[i] = onChangeValue.target.value;
    setAwardsVal(inputdata);
  };
  const handleAwardDelete = (i) => {
    const deletVal = [...awardsVal];
    deletVal.splice(i, 1);
    setAwardsVal(deletVal);
  };

  const handleName = (e) => {
    setNameError(null);
    setNameVal(e.target.value);
  };
  const handleSurname = (e) => {
    setNameError(null);
    setSurnameVal(e.target.value);
  };
  const handlePatronymic = (e) => {
    setNameError(null);
    setPatronymicVal(e.target.value);
  };

  const handleDate = (e) => {
    setAgeError(null);
    setDateVal(e);
  };

  const handleFin = (e) => {
    setFinError(null);
    setFinVal(e.target.value);
  };
  const handleBirthCert = (e) => {
    setBirthCertError(null);
    setBirthCert(e.target.value);
  };

  const handleRegAddress = (e) => {
    setRegAddressError(null);
    setRegAddress(e.target.value);
  };
  const handleFactAddress = (e) => {
    setFactAddressError(null);
    setFactAddress(e.target.value);
  };

  const handleSchool = (val) => {
    setSchoolError(null);
    setSchool(val);
  };
  const handleArtSchoolDetails = (e) => {
    setArtSchoolDetails(e.target.value);
  };
  const handleAwardsVal = (e) => {
    setAwardVal(e.target.value);
  };
  const handleCity = (e) => {
    setCityError(null);
    setCity(e);
  };
  const handleNomination = (e) => {
    setNominationError(null);
    setNomination(e.target.value);
  };
  const handleImage = (e) => {
    setImageError(null);
    setImageLink(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  };

  const handleSerial = (e) => {
    setSerialError(null);
    setSerialVal(e.target.value);
  };
  const handleSpecialSchool = (val) => {
    setSpecialSchool(val);
  };
  const handleParentsName = (e) => {
    setParentsNameError(null);
    setParentsName(e.target.value);
  };
  const handleParentsSurname = (e) => {
    setParentsNameError(null);
    setParentsSurname(e.target.value);
  };
  const handleParentsPatronymic = (e) => {
    setParentsNameError(null);
    setParentsPatronymic(e.target.value);
  };
  const handleParentsFin = (e) => {
    setParentsFinError(null);
    setParentsFin(e.target.value);
  };
  const handleParentsSerial = (e) => {
    setParentsSerialError(null);
    setParentsSerial(e.target.value);
  };
  const handleParentsMobile = (e, type) => {
    setParentsMobileError((prev) => ({ ...prev, [type]: "" }));
    let data = {
      prefix: parentsMobile[type]?.prefix,
      number: e.target.value,
    };
    setParentsMobile((prev) => ({ ...prev, [type]: data }));
  };

  const handleParentsMobilePrefix = (e, type) => {
    let data = {
      prefix: e.target.value,
      number: parentsMobile[type]?.number,
    };
    setParentsMobile((prev) => ({ ...prev, [type]: data }));
  };

  const handleParentsEmail = (e) => {
    setParentsEmailError(null)
    setParentsEmail(e.target.value);
  };
  const handleRules = () => {
    setRules(!rules);
  };
  const handleAgreement = () => {
    setAgreement(!agreement);
  };
  const handleSchoolType = (id) => {
    if (id) {
      setSelectedSchoolType(id);
      form2.setFieldValue("school_id", null);
      setEducationalSchools([]);
    } else {
      setSelectedSchoolType(null);
    }
  };
  const artSchoolPlaceholder = () => {
    if (specialSchool) return specialSchool[1];
  };
  let error;

  const handleInformation = () => {
    error = 0;
    // let age = getAge(dateVal);
    if (page === 1) {
      if (!image) {
        setImageError("Foto yüklənməyib");
        error++;
      }
      if (image.size > 5242881) {
        setImageError("Foto həcmi 5MB artıq ola bilməz");
        error++;
      }
      if (!nameVal || !surnameVal || !patronymicVal) {
        setNameError("İştirakçı adı, soyadı və ya ata adı doldurulmayıb");
        error++;
      }
      if (!dateVal) {
        setAgeError("Doğum tarixi daxil edilməyib");
        error++;
      }
      if (!regAddress) {
        setRegAddressError("Qeydiyyat ünvanı daxil edilməyib");
        error++;
      }
      if (!factAddress) {
        setFactAddressError("Faktiki yaşayış ünvanı daxil edilməyib");
        error++;
      }
      if (!finVal) {
        setFinError("Fin kod daxil edilməyib");
        error++;
      }
      if (!serialVal) {
        setSerialError("Şəxsiyyət vəsiqəsinin seriya nömrəsi daxil edilməyib");
        error++;
      }
      if (!city) {
        setCityError("Şəhər daxil edilməyib");
        error++;
      }

      // if (!disabled) {
      //   setSearchError("Fin və seriyanı qeyd etdikdən sonra axtarış düyməsinə klik edin.")
      //   error++;
      // }

      if (error == 0) {
        validateFirstPage();
      }
    }
    if (page === 3) {
      if (!parentsName || !parentsSurname || !parentsPatronymic) {
        setParentsNameError("Ad, soyad və ya ata adı daxil edilməyib");
        error++;
      }
      if (!parentsFin) {
        setParentsFinError("Fin kod daxil edilməyib");
        error++;
      }
      if (!parentsSerial) {
        setParentsSerialError(
          "Şəxsiyyət vəsiqəsinin seriya nömrəsi daxil edilməyib"
        );
        error++;
      }

      if (!parentsMobile.first.number) {
        setParentsMobileError((prev) => ({
          ...prev,
          first: "Əlaqə nömrəsi daxil edilməyib",
        }));
        error++;
      } else if (parentsMobile.first.number.length !== 7) {
        setParentsMobileError((prev) => ({
          ...prev,
          first: "Əlaqə nömrəsinin uzunluğu 7 olmalıdır",
        }));
        error++;
      }

      if (!parentsMobile.second.number) {
        setParentsMobileError((prev) => ({
          ...prev,
          second: "Əlaqə nömrəsi daxil edilməyib",
        }));
        error++;
      } else if (parentsMobile.second.number.length !== 7) {
        setParentsMobileError((prev) => ({
          ...prev,
          second: "Əlaqə nömrəsinin uzunluğu 7 olmalıdır",
        }));
        error++;
      }

      if (!parentsEmail) {
        setParentsEmailError("Email daxil edilməyib");
        error++;
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(parentsEmail)) {
        setParentsEmailError("Email düzgün deyil");
        error++;
      }

      if (error == 0) {
        validateThirdPage();
      }
    }
  };

  async function handleSend() {
    if (error == 0) {
      let infoObj;
      // if (finRadio === 1) {
      infoObj = {
        // page 1
        photo: image,
        image_type: imageType,
        name: studentFromAsan?.name || nameVal,
        surname: studentFromAsan?.surname || surnameVal,
        patronymic: studentFromAsan?.patronymic || patronymicVal,
        birth_date: studentFromAsan?.birth_date || dayjs(dateVal).format(dateFormat),
        fin_code: studentFromAsan?.fin_code || finVal,
        registration_address: studentFromAsan?.registration_address || regAddress,
        live_address: factAddress,
        serial_number: studentFromAsan?.serial_number || serialVal,
        card_old_or_new: studentFromAsan?.card_old_or_new || serialRadio,
        gender: studentFromAsan?.gender || genderRadio,
        mn_region_id: city,

        // page 2
        city_id: citySecond,
        school_id: school,
        school_type_id: selectedSchoolType,
        nomination_id: nomination,
        art_type: artSchool,
        art_education: artSchool == 1 ? artEducation : artSchoolDetails,
        awards_name: awardVal?.trim() ? [...awardsVal, awardVal] : awardsVal,

        // page 3
        parent_name: parentFromAsan?.parent_name || parentsName,
        parent_surname: parentFromAsan?.parent_surname || parentsSurname,
        parent_patronymic: parentFromAsan?.parent_patronymic || parentsPatronymic,
        parent_fin_code: parentFromAsan?.parent_fin_code || parentsFin,
        parent_serial_number: parentFromAsan?.parent_serial_number || parentsSerial,
        parents_card_old_or_new: parentFromAsan?.parents_card_old_or_new || parentsSerialRadio,
        email: parentsEmail,
        first_prefix: parentsMobile.first.prefix,
        second_prefix: parentsMobile.second.prefix,
        first_phone_number: parentsMobile.first.number,
        second_phone_number: parentsMobile.second.number,
      };
      try {
        setLoading(true)
        const res = await personal.sendPersonalData(infoObj);
        setCode(res.data.message);
        setPage((prev) => prev + 1);
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
  }

  async function validateFirstPage() {
    if (!isSearched) {
      setFirstPageError('Seriya nömrəsi və fin kod ilə axtarış edin.')
      return
    } else {
      setFirstPageError(null);
    }
    let firstPage = new FormData();
    if (error === 0) {
      firstPage.append("photo", image);
      firstPage.append("name", studentFromAsan?.name || nameVal);
      firstPage.append("surname", studentFromAsan?.surname || surnameVal);
      firstPage.append("patronymic", studentFromAsan?.patronymic || patronymicVal);
      firstPage.append("birth_date", studentFromAsan?.birth_date || dayjs(dateVal).format(dateFormat));
      firstPage.append("registration_address", studentFromAsan?.registration_address || regAddress);
      firstPage.append("image_type", imageType);
      firstPage.append("live_address", factAddress);
      firstPage.append("fin_code", studentFromAsan?.fin_code || finVal);
      firstPage.append("serial_number", studentFromAsan?.serial_number || serialVal);
      firstPage.append("card_old_or_new", studentFromAsan?.card_old_or_new || serialRadio);
      firstPage.append("gender", studentFromAsan?.gender || genderRadio);
      firstPage.append("mn_region_id", city);
      try {
        setLoading(true)
        const data = await personalValidation.sendFirstPage(firstPage);
        if (data.data.message === "OK") {
          setPage((prev) => prev + 1);
        }
      } catch (error) {
        setFirstPageError(error.response.data.message);
      } finally {
        setLoading(false)
      }
    }
  }

  async function validateSecondPage(data) {
    setSecondPageError(null);
    const secondPage = {
      ...data,
      art_type: imesUser?.custom_code === 2 ? 1 : 2,
      art_education:
        imesUser?.custom_code === 2 ? data.art_education : artSchoolDetails,
      awards_name: awardVal?.trim() ? [awardVal, ...awardsVal] : awardsVal
    };
    try {
      setLoading(true)
      const res = await personalValidation.sendSecondPage(secondPage);
      if (res.data.message === "OK") {
        setSecondPageData(secondPage)
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      setSecondPageError(error.response.data.message);
    } finally {
      setLoading(false)
    }
  }

  async function validateThirdPage() {
    setThirdPageError(null);
    if (error === 0) {
      const ThirdPage = {
        parent_name: parentFromAsan?.name || parentsName,
        parent_surname: parentFromAsan?.surname || parentsSurname,
        parent_patronymic: parentFromAsan?.patronymic || parentsPatronymic,
        parent_fin_code: parentFromAsan?.fin_code || parentsFin,
        parent_serial_number: parentFromAsan?.serial_number || parentsSerial,
        parents_card_old_or_new: parentFromAsan?.card_old_or_new || parentsSerialRadio,
        email: parentsEmail,
        first_prefix: parentsMobile.first.prefix,
        second_prefix: parentsMobile.second.prefix,
        first_phone_number: parentsMobile.first.number,
        second_phone_number: parentsMobile.second.number,
      };
      try {
        setLoading(true)
        const res = await personalValidation.sendThirdPage(ThirdPage);
        if (res.data.message === "OK") {
          handleSend();
        }
      } catch (error) {
        setThirdPageError(error.response.data.message);
        if (error.response.data.errors.first_phone_number) {
          setParentsMobileError(prev => ({ ...prev, first: error.response.data.errors.first_phone_number?.join(". ") }))
        } else if (error.response.data.errors.second_phone_number) {
          setParentsMobileError(prev => ({ ...prev, second: error.response.data.errors.second_phone_number?.join(". ") }))
        }
      } finally {
        setLoading(false)
      }
    }
  }

  const [isPlaceholder, setIsPlaceholder] = useState(true); // Placeholder göstərmək üçün state

  const handleDateChange = (e) => {
    setDateVal(e.target.value);
    setIsPlaceholder(e.target.value === ""); // Boşdursa placeholder göstər
  };

  function getAge(birthDateString) {
    var today = new Date();
    var birthDate = new Date(birthDateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  useEffect(() => {
    async function fetchNominations() {
      const { data } = await Nominations.getNominations();
      setNominationsSelect(data);
    }

    async function fetchEducationSchoolsTypes() {
      const { data } = await Schools.getEducationSchoolsTypes();
      setSchoolTypes(data);
    }

    async function fetchRegions() {
      const { data } = await Regions.getRegions()
      setRegionsSelect(data)
    }
    fetchRegions()
    fetchNominations();
    fetchEducationSchoolsTypes();
    getCitiesData();
  }, []);

  const getEducationSchools = async () => {
    try {
      const res = await Schools.getEducationSchools({
        school_type: selectedSchoolType,
      });
      setEducationalSchools(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (page === 2) {
      getPersonalDataFromImes();
    }
  }, [page]);

  const getPersonalDataFromImes = async () => {
    try {
      const res = await personal.getPersonalData({
        pin: finVal,
        serial_number: serialVal,
      });
      let obj = {
        custom_code: res.data.custom_code,
        school_name: res.data.data.name,
      };
      if (obj.custom_code === 2) {
        setArtSchool(1);
      } else {
        setArtSchool(2);
      }
      setImesUser(obj);
      form2.setFieldValue("art_education", obj.school_name);
      setArtEducation(obj.school_name)
    } catch (error) {
      setImesUser(null)
      console.log(error);
    }
  };

  const setSearchFin = async () => {
    setFinError(null);
    setAgeError(null)
    setRegAddressError(null)
    setFactAddressError(null)
    setSerialError(null)
    setImageError(null)
    setNameError(null)
    let obj = {
      fin_code: finVal,
      serial_number: serialVal,
      card_old_or_new: serialRadio,
    };
    try {
      if (!obj.serial_number) {
        setSerialError('Seriya nömrəsi daxil edilməyib')
        return
      }
      if (!obj.fin_code) {
        setFinError('Fin kod daxil edilməyib')
        return
      }
      if (obj.card_old_or_new && obj.fin_code && obj.serial_number) {
        setIsSearched(true)
        const res = await instance.get(`${"/get-personal-data"}`, {
          params: obj,
        });
        const asanUser = res.data.data;
        setDateVal(dayjs(asanUser?.birth_date, dateFormat));
        setNameVal(asanUser.name);
        setPatronymicVal(asanUser.patronymic);
        setSurnameVal(asanUser.surname);
        setGenderRadio(asanUser.gender);
        setRegAddress(asanUser.registration_address);
        setImage(asanUser.photo);
        setAsanImage(asanUser.photo);
        if (asanUser.photo) {
          setImageType(1);
          setImageError(null);
        } else {
          setImageType(2);
        }
        setStudentFromAsan(asanUser);
        setDisabled(true)
      }
    } catch (error) {
      setStudentFromAsan(null)
      setDateVal(null);
      setNameVal(null);
      setPatronymicVal(null);
      setSurnameVal(null);
      setGenderRadio("MALE");
      setRegAddress(null);
      setImage(null);
      setAsanImage(null);
      if (error.response.data.code === 2) {
        setDisabled(false);
      }
      setSerialError(error.response.data.errors?.serial_number);
      setFinError(error.response.data.errors?.fin_code);

      setSearchError(error.response.data.message);

    }
  };

  const searchParent = async () => {
    setParentsFinError(null)
    setParentsSerialError(null)
    setParentsNameError(null)
    try {
      const obj = {
        parent_fin_code: parentsFin,
        parent_serial_number: parentsSerial,
        parents_card_old_or_new: parentsSerialRadio,
      };
      if (!obj.parent_serial_number) {
        setParentsSerialError('Seriya nömrəsi daxil edilməyib')
        return
      }
      if (!obj.parent_fin_code) {
        setParentsFinError('Fin kod daxil edilməyib')
        return
      }
      if (obj.parent_fin_code && obj.parent_serial_number && obj.parents_card_old_or_new) {
        const res = await instance.get("/personal-parent-data", {
          params: obj,
        });
        let user = res.data.data;
        setParentsName(user.parent_name);
        setParentsSurname(user.parent_surname);
        setParentsPatronymic(user.parent_patronymic);
        setParentFromAsan(user);
        setDisabled(true)
      }
    } catch (error) {
      setParentFromAsan(null)
      setParentsName(null);
      setParentsSurname(null);
      setParentsPatronymic(null);
      if (error.response.data.code === 2) {
        setDisabled(false);
      }
      setParentsSerialError(error.response.data.errors?.parent_serial_number);
      setParentsFinError(error.response.data.errors?.parent_fin_code);
      setSearchError(error.response.data.message);

    }
  };

  const getCitiesData = async () => {
    try {
      if (citiesSelect.length === 0) {
        const res = await getAllCities();
        setCitiesSelect(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <section className={styles["section"]}>
        {loading && <Loading />}
        {page === 1 ? (
          <>
            <Wrapper>
              <Link to={"/registration-test"} className={styles["go-back"]}>
                <BackArrow />
                <p>Geri qayıt</p>
              </Link>
            </Wrapper>
            <div className={styles["wrapper"]}>
              <h2 className={styles["reg"]}>Fərdi qeydiyyat</h2>
              <div className={styles["progress"]}>
                <div className={styles["progressed"]}></div>
                <div className={styles["step1"]}>1</div>
                <div className={styles["step2"]}>2</div>
                <div className={styles["step3"]}>3</div>
              </div>
              <div className={styles["progress-name"]}>
                <p>Şəxsi məlumatlar</p>
                <p className={styles["inactive-name"]}>Təhsil və İncəsənət</p>
                <p className={styles["inactive-name"]}>
                  Valideyn və ya qanuni nümayəndə
                </p>
              </div>
              <form action="">
                <div className={styles["double-radio"]}>
                  <div className={styles["search_container"]}>
                    <div style={{ display: "flex" }}>
                      <select
                        className={styles["seriya"]}
                        onChange={(e) => setSerialRadio(e.target.value)}
                      >
                        <option value="1" selected={serialRadio === "1"}>
                          AA
                        </option>
                        <option value="2" selected={serialRadio === "2"}>
                          AZE
                        </option>
                      </select>
                      <input
                        type="number"
                        min="0"
                        inputmode="numeric"
                        name=""
                        id=""
                        placeholder="Seriya nömrəsi"
                        onChange={(e) => handleSerial(e)}
                        style={{ borderRadius: "0px 10px 10px 0px" }}
                        value={serialVal}
                      />
                    </div>
                    <input
                      type="text"
                      name=""
                      id=""
                      style={{ width: 35 + "%" }}
                      placeholder="FİN kod"
                      disabled={finRadio == 1 ? false : true}
                      onChange={(e) => handleFin(e)}
                      value={finVal}
                    />

                    <div className={styles["search_buttons"]}>
                      <button
                        type="button"
                        className={styles["info-button"]}
                        onClick={() => setFinPopup(true)}
                      >
                        ?
                      </button>
                      <button
                        type="button"
                        className={styles["search"]}
                        onClick={() => setSearchFin()}
                      >
                        <img src={searchImg} alt="search" />
                      </button>
                    </div>
                  </div>
                  {finError ? (
                    <p className={styles["error"]}>
                      {finError}
                    </p>
                  ) : null}

                  {serialError ? (
                    <span className={styles["error"]}>{serialError}</span>
                  ) : null}
                </div>
                <div className={styles["input-photo"]}>
                  <div className={styles["two-two-input"]}>
                    <div className={styles["two-input"]}>
                      <label htmlFor="">İştirakçının adı</label>
                      <input
                        type="text"
                        disabled={(studentFromAsan && !studentFromAsan?.name) ? false : disabled}
                        placeholder="İştirakçının adı"
                        onChange={(e) => handleName(e)}
                        value={nameVal}
                      />
                      <label htmlFor="">Soyadı</label>
                      <input
                        type="text"
                        disabled={(studentFromAsan && !studentFromAsan?.surname) ? false : disabled}
                        placeholder="Soyadı"
                        onChange={(e) => handleSurname(e)}
                        value={surnameVal}
                      />
                      <p
                        className={styles["error"]}
                        style={{ width: 200 + "px" }}
                      >
                        {nameError}
                      </p>
                    </div>
                    <div>
                      <label htmlFor="">Atasının adı</label>
                      <input
                        type="text"
                        disabled={(studentFromAsan && !studentFromAsan?.patronymic) ? false : disabled}
                        placeholder="Atasının adı"
                        onChange={(e) => handlePatronymic(e)}
                        value={patronymicVal}
                      />
                      <label htmlFor="">Doğum tarixi</label>

                      <DatePicker
                        disabled={(studentFromAsan && !studentFromAsan?.birth_date) ? false : disabled}
                        format={dateFormat}
                        placeholder="il.ay.gün"
                        onChange={(e) => handleDate(e)}
                        value={dateVal}
                      />
                      <p
                        className={styles["error"]}
                        style={{ width: 200 + "px" }}
                      >
                        {ageError}
                      </p>
                    </div>
                  </div>
                  <div className={styles["image-container"]}>
                    <label
                      className={styles["image"]}
                      style={{
                        backgroundImage: asanImage ?
                          `url(data:image/png;base64,${asanImage}` :
                          `url(${imageLink}`
                      }}
                    >
                      <input
                        type="file"
                        accept="image"
                        onChange={(e) => handleImage(e)}
                        files={image}
                        disabled={imageType === 1}
                      />
                      {!image ? <p>Foto yüklə</p> : null}
                    </label>
                    {imageError ? (
                      <p className={styles["error"]}>{imageError}</p>
                    ) : null}
                    <button
                      type="button"
                      className={styles["info-button"]}
                      onClick={() => setImagePopup(true)}
                    >
                      ?
                    </button>
                  </div>
                </div>
                <div className={styles["inputs"]}>
                  <label htmlFor="">Cinsi</label>
                  <div
                    className={styles["db-radio-flex"]}
                    style={{ display: "flex" }}
                  >
                    <div className={styles["radio-label"]}>
                      <input
                        disabled={(studentFromAsan && !studentFromAsan?.gender) ? false : disabled}
                        type="radio"
                        checked={genderRadio == "MALE" ? true : false}
                        onClick={() => {
                          setGenderRadio("MALE");
                        }}
                      />
                      <p>Kişi</p>
                    </div>
                    <div className={styles["radio-label"]}>
                      <input
                        disabled={(studentFromAsan && !studentFromAsan?.gender) ? false : disabled}
                        type="radio"
                        checked={genderRadio == "MALE" ? false : true}
                        onClick={() => {
                          setGenderRadio("FEMALE");
                        }}
                      />
                      <p>Qadın</p>
                    </div>
                  </div>

                  <label htmlFor="">Qeydiyyat ünvanı</label>
                  <input
                    type="text"
                    disabled={(studentFromAsan && !studentFromAsan?.registration_address) ? false : disabled}
                    placeholder="Qeydiyyat ünvanı"
                    onChange={(e) => handleRegAddress(e)}
                    value={regAddress}
                  />
                  {regAddressError ? (
                    <p className={styles["error"]}>{regAddressError}</p>
                  ) : null}

                  <label htmlFor="">Faktiki yaşayış ünvanı</label>

                  <div className={styles["address"]}>
                    <div className={styles["cityy"]}>
                      <Select
                        className={styles["region_select"]}
                        placeholder="Şəhər/rayon"
                        showSearch
                        value={city}
                        onChange={handleCity}
                        options={regionsSelect.map(item => ({
                          value: item.id,
                          label: item.name
                        }))}

                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                      </Select>
                      {cityError ? (
                        <p className={styles["error"]}>{cityError}</p>
                      ) : null}
                    </div>
                    <div className={styles["fact"]}>
                      <Input
                        style={{ height: 43, width: '100%' }}
                        value={factAddress}
                        onChange={e => handleFactAddress(e)}
                        placeholder="Faktiki yaşayış ünvanı"
                      />
                      {factAddressError ? (
                        <p className={styles["error"]}>{factAddressError}</p>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className={styles["next-container"]}>
                  <button
                    type="button"
                    className={styles["next"]}
                    onClick={() => handleInformation()}
                  >
                    Növbəti
                  </button>
                </div>
              </form>
              {finPopup ? (
                <InfoPopup
                  handleChange={setFinPopup}
                  photos={[FinNew, FinAndSerialOld]}
                />
              ) : null}
              {serialPopup ? (
                <InfoPopup
                  handleChange={setSerialPopup}
                  photos={[SerialNew, FinAndSerialOld]}
                />
              ) : null}
              {birthCertPopup ? (
                <InfoPopup
                  handleChange={setBirthCertPopup}
                  photos={[BirthCertImg, ""]}
                  tabs={false}
                />
              ) : null}
              {imagePopup ? (
                <AgreementPopup handleChange={setImagePopup}>
                  Fotoşəkil üçün təlimatlar:
                  <div className={styles["imageInformation"]}>
                    <span>
                      •⁠ <strong>Ölçü:</strong> 3,5 × 4,5 sm (pasport ölçüsü)
                    </span>
                    <span>
                      •⁠ <strong>Keyfiyyət:</strong> Şəkil adekvat parlaqlıq və
                      kontrasta malik olmalıdır
                    </span>
                    <span>
                      •⁠ <strong>Kompozisiya:</strong> Baş və çiyinlərin yuxarı
                      hissəsi tam görünməlidir
                    </span>
                    <span>
                      •⁠ <strong>Pozisiya:</strong> Şəxs düz, neytral mimika ilə
                      kameraya baxmalıdır
                    </span>
                    <span>
                      •⁠ <strong>Gözlər:</strong> Açıq və tam görünən olmalıdır
                    </span>
                    <span>
                      •⁠ <strong>Üz örtükləri:</strong> aç, baş geyimi, eynək və
                      ya digər aksesuarlar üzü qapamamalıdır
                    </span>
                    <span>
                      •⁠⁠ <strong>Fon:</strong> çıq rəngdə və vahid olmalıdır
                    </span>
                    <span>
                      •⁠⁠ <strong>Obyektlər:</strong> Şəkildə yalnız fotoşəkil
                      sahibinin özü olmalıdır, arxa planda digər insanlar və ya
                      obyektlər olmamalıdır
                    </span>
                  </div>
                </AgreementPopup>
              ) : null}
              {searchError ? (
                <AgreementPopup handleChange={setSearchError}>
                  {searchError}
                </AgreementPopup>
              ) : null}
              {firstPageError && <AgreementPopup handleChange={setFirstPageError}>
                {firstPageError}
              </AgreementPopup>}
            </div>
          </>
        ) : null}

        {page === 2 ? (
          <>
            <Wrapper>
              <div onClick={() => setPage(1)} className={styles["go-back"]}>
                <BackArrow />
                <p>Geri qayıt</p>
              </div>
            </Wrapper>
            <div className={styles["wrapper"]}>
              <h2 className={styles["reg"]}>Fərdi qeydiyyat</h2>
              <div className={styles["progress"]}>
                <div
                  className={styles["progressed"]}
                  style={{ width: 66 + "%" }}
                ></div>
                <div className={styles["step1"]}>1</div>
                <div className={styles["step2-2"]}>2</div>
                <div className={styles["step3"]}>3</div>
              </div>
              <div className={styles["progress-name"]}>
                <p>Şəxsi məlumatlar</p>
                <p>Təhsil və İncəsənət</p>
                <p className={styles["inactive-name"]}>
                  Valideyn və ya qanuni nümayəndə
                </p>
              </div>
              <Form
                layout="vertical"
                form={form2}
                onFinish={validateSecondPage}
              >
                <div className={styles["city"]}>
                  <Form.Item
                    label="Müraciət etdiyi şəhər/rayon"
                    name={"city_id"}
                    rules={[{ required: true, message: "Bu xana məcburidir" }]}
                  >
                    <Select
                      value={citySecond}
                      onChange={id => setCitySecond(id)}
                      size="large"
                      options={citiesSelect.map((item) => ({
                        value: item.id,
                        label: item.city_name,
                      }))}
                      placeholder="Şəhər seçin"
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    />
                  </Form.Item>
                </div>
                {cityError ? (
                  <p className={styles["error"]}>{cityError}</p>
                ) : null}
                <div className={styles["school"]}>
                  <Form.Item
                    label="Təhsil aldığı təhsil müəssisəsinin növü"
                    name={"school_type_id"}
                    rules={[{ required: true, message: "Bu xana məcburidir" }]}
                  >
                    <Select
                      value={selectedSchoolType}
                      onChange={(id) => handleSchoolType(id)}
                      options={schoolTypes.map((item) => ({
                        value: item.id,
                        label: item.school_type,
                      }))}
                      size="large"
                      placeholder="Növ"
                    />
                  </Form.Item>
                </div>

                <div className={styles["school"]}>
                  <Form.Item
                    label="Təhsil aldığı təhsil müəssisə"
                    name="school_id"
                    rules={[{ required: true, message: "Bu xana məcburidir" }]}
                  >
                    <Select
                      value={school}
                      onChange={id => setSchool(id)}
                      disabled={!selectedSchoolType}
                      placeholder="Müəssisə seçin"
                      onClick={getEducationSchools}
                      options={educationalSchools.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                      showSearch
                      size="large"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    />
                  </Form.Item>
                </div>
                {schoolError ? (
                  <p className={styles["error"]}>{schoolError}</p>
                ) : null}
                <div className={styles["nomination"]}>
                  <Form.Item
                    label="Müraciət etdiyi nominasiya"
                    name="nomination_id"
                    rules={[{ required: true, message: "Bu xana məcburidir" }]}
                  >
                    <Select
                      size="large"
                      showSearch
                      value={nomination}
                      onChange={id => setNomination(id)}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={nominationsSelect.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                      placeholder="Nominasiya seçin"
                    />
                  </Form.Item>
                </div>
                {nominationError ? (
                  <p className={styles["error"]}>{nominationError}</p>
                ) : null}
                <div className={styles["art-ed"]}>
                  <label>
                    Seçilən nominasiya üzrə xüsusi incəsənət təhsili
                  </label>
                  <div style={{ display: "flex", gap: 100 + "px" }}>
                    <div style={{ display: "flex" }}>
                      <input
                        type="radio"
                        disabled={true}
                        checked={artSchool == 1 ? true : false}
                      />
                      <span>Var</span>
                    </div>
                    <div style={{ display: "flex" }}>
                      <input
                        type="radio"
                        disabled={true}
                        checked={artSchool == 1 ? false : true}
                      />
                      <span>Yoxdur</span>
                    </div>
                  </div>
                </div>
                {artSchool == 1 ? (
                  <div className={styles["nomination"]}>
                    <Form.Item
                      label="Xüsusi incəsənət təhsil müəssisəsi"
                      name="art_education"
                      rules={[
                        { required: true, message: "Bu xana məcburidir" },
                      ]}
                    >
                      <Select
                        size="large"
                        showSearch
                        value={artEducation}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={artSchoolsSelect.map((item) => ({
                          value: item.id,
                          label: item.school_name,
                        }))}
                      />
                    </Form.Item>
                  </div>
                ) :
                  (
                    <div className={styles["text-area"]}>
                      <label htmlFor="">Digər</label>
                      <textarea
                        onChange={(e) => handleArtSchoolDetails(e)}
                        id=""
                        name=""
                        rows="10"
                        placeholder="Xüsusi incəsənət təhsili (əgər varsa, detallar əlavə olunur)"
                        value={artSchoolDetails}
                      ></textarea>
                    </div>
                  )
                }

                <div>
                  <div className={styles["awards"]}>
                    <label htmlFor="">Təltiflər</label>
                    <input
                      type="text"
                      placeholder="Təltif"
                      onChange={(e) => handleAwardsVal(e)}
                      style={{ marginBottom: 10 + "px" }}
                      value={awardVal}
                    />
                    {awardsVal.map((data, i) => {
                      return (
                        <div className={styles["added-award"]}>
                          <input
                            value={data}
                            onChange={(e) => handleAwardChange(e, i)}
                            placeholder="Təltif"
                          />
                          <button
                            type="button"
                            onClick={() => handleAwardDelete(i)}
                          >
                            Sil
                          </button>
                        </div>
                      );
                    })}
                  </div>
                  <div className={styles["add-container"]}>
                    <button
                      type="button"
                      className={styles["add"]}
                      onClick={() => handleAwardAdd()}
                    >
                      Əlavə et
                    </button>
                  </div>
                </div>
                {secondPageError ? (
                  <>
                    <AgreementPopup
                      handleChange={setSecondPageError}
                      error={true}
                    >
                      {secondPageError}
                    </AgreementPopup>
                  </>
                ) : null}
                <div className={styles["next-container"]}>
                  <button type="submit" className={styles["next"]}>
                    Növbəti
                  </button>
                </div>
              </Form>
            </div>
          </>
        ) : null}

        {page === 3 ? (
          <>
            <Wrapper>
              <div onClick={() => setPage(2)} className={styles["go-back"]}>
                <BackArrow />
                <p>Geri qayıt</p>
              </div>
            </Wrapper>
            <div className={styles["wrapper"]}>
              <h2 className={styles["reg"]}>Fərdi qeydiyyat</h2>
              <div className={styles["progress"]}>
                <div
                  className={styles["progressed"]}
                  style={{ width: 100 + "%" }}
                ></div>
                <div className={styles["step1"]}>1</div>
                <div className={styles["step2-2"]}>2</div>
                <div className={styles["step3-3"]}>3</div>
              </div>
              <div className={styles["progress-name"]}>
                <p>Şəxsi məlumatlar</p>
                <p>Təhsil və İncəsənət</p>
                <p style={{ maxWidth: 130 + "px" }}>
                  Valideyn və ya qanuni nümayəndə
                </p>
              </div>
              <form action="">
                <p className={styles["disclaimer"]}>
                  *Bu səhifə valideyn/qanuni nümayəndə tərəfindən
                  doldurulmalıdır. Nəzərə alınmalıdır ki, bütün məlumat bölgüsü
                  bu səhifədə göstərilən əlaqə vasitələri ilə paylaşılacaqdır.
                </p>
                <div
                  className={styles["double-radio"]}
                  style={{ marginTop: 10 }}
                >
                  <div>
                    <div className={styles["search_container"]}>
                      <div style={{ display: "flex" }}>
                        <select
                          style={{ width: 50 + "%" }}
                          className={styles["seriya"]}
                          onChange={(e) =>
                            setParentsSerialRadio(e.target.value)
                          }
                        >
                          <option
                            value="1"
                            selected={parentsSerialRadio === "1"}
                          >
                            AA
                          </option>
                          <option
                            value="2"
                            selected={parentsSerialRadio === "2"}
                          >
                            AZE
                          </option>
                        </select>
                        <input
                          type="number"
                          min="0"
                          inputmode="numeric"
                          name=""
                          id=""
                          placeholder="Seriya nömrəsi"
                          onChange={(e) => handleParentsSerial(e)}
                          value={parentsSerial}
                          style={{ borderRadius: "0px 10px 10px 0px" }}
                          disabled={finRadio == 2 ? true : false}
                        />
                      </div>
                      <input
                        type="text"
                        name=""
                        id=""
                        style={{ width: 30 + "%" }}
                        placeholder="FİN kod"
                        disabled={finRadio == 1 ? false : true}
                        onChange={(e) => handleParentsFin(e)}
                        value={parentsFin}
                      />

                      <div className={styles["search_buttons"]}>
                        <button
                          type="button"
                          className={styles["info-button"]}
                          onClick={() => setFinPopup(true)}
                        >
                          ?
                        </button>
                        <button
                          type="button"
                          className={styles["search"]}
                          onClick={() => searchParent()}
                        >
                          <img src={searchImg} alt="search" />
                        </button>
                      </div>
                    </div>
                    {parentsFinError ? (
                      <span className={styles["error"]}>{parentsFinError}</span>
                    ) : null}
                    <br />
                    {parentsSerialError ? (
                      <span className={styles["error"]}>
                        {parentsSerialError}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className={styles["parent_inputs_container"]}>
                  <div className={styles["parent_names_inputs"]}>
                    <label htmlFor="">Ad</label>
                    <input
                      type="text"
                      placeholder="Ad"
                      onChange={(e) => handleParentsName(e)}
                      value={parentsName}
                      disabled={(parentFromAsan && parentFromAsan?.name) ? false : disabled}
                    />
                  </div>
                  <div className={styles["parent_names_inputs"]}>
                    <label htmlFor="">Soyad</label>
                    <input
                      type="text"
                      placeholder="Soyad"
                      onChange={(e) => handleParentsSurname(e)}
                      value={parentsSurname}
                      disabled={(parentFromAsan && parentFromAsan?.surname) ? false : disabled}
                    />
                  </div>
                  <div className={styles["parent_names_inputs"]}>
                    <label htmlFor="">Ata adı</label>
                    <input
                      type="text"
                      placeholder="Ata adı"
                      onChange={(e) => handleParentsPatronymic(e)}
                      value={parentsPatronymic}
                      disabled={(parentFromAsan && parentFromAsan?.patronymic) ? false : disabled}
                    />
                  </div>
                </div>
                {parentsNameError ? (
                  <p className={styles["error"]}>{parentsNameError}</p>
                ) : null}

                <div className={styles["contact_inputs"]}>
                  <div>
                    <label>Əlaqə nömrəsi</label>
                    <div style={{ display: "flex" }}>
                      <select
                        style={{ width: 70 }}
                        className={styles["seriya"]}
                        onChange={(e) => handleParentsMobilePrefix(e, "first")}
                      >
                        {prefixes.map((prefix) => (
                          <option key={prefix.value}>{prefix.label}</option>
                        ))}
                      </select>
                      <input
                        type="number"
                        min="0"
                        inputmode="numeric"
                        placeholder="Nömrə"
                        onChange={(e) => handleParentsMobile(e, "first")}
                        value={parentsMobile.first.number}
                        style={{ borderRadius: "0px 10px 10px 0px" }}
                        disabled={finRadio == 2}
                      />
                    </div>
                    {parentsMobileError?.first && (
                      <p className={styles["error"]}>
                        {parentsMobileError.first}
                      </p>
                    )}
                  </div>

                  <div>
                    <label>Əlaqə nömrəsi</label>
                    <div style={{ display: "flex" }}>
                      <select
                        style={{ width: 70 }}
                        className={styles["seriya"]}
                        onChange={(e) => handleParentsMobilePrefix(e, "second")}
                      >
                        {prefixes.map((prefix) => (
                          <option key={prefix.value}>{prefix.label}</option>
                        ))}
                      </select>
                      <input
                        type="number"
                        min="0"
                        inputmode="numeric"
                        placeholder="Nömrə"
                        onChange={(e) => handleParentsMobile(e, "second")}
                        value={parentsMobile.second.number}
                        style={{ borderRadius: "0px 10px 10px 0px" }}
                        disabled={finRadio == 2}
                      />
                    </div>
                    {parentsMobileError?.second && (
                      <p className={styles["error"]}>
                        {parentsMobileError.second}
                      </p>
                    )}
                  </div>
                </div>

                <div style={{ marginBottom: 20 }}>
                  <label htmlFor="">Email</label>
                  <div>
                    <input
                      type="email"
                      placeholder="Email"
                      onChange={(e) => handleParentsEmail(e)}
                      value={parentsEmail}
                      style={{ boxSizing: "border-box", width: "100%" }}
                    />
                  </div>
                  {parentsEmailError && (
                    <p className={styles["error"]}>{parentsEmailError}</p>
                  )}
                </div>

                {/* {parentsMobileError ? (
                  <p className={styles["error"]}>{parentsMobileError}</p>
                ) : null} */}
                <div className={styles["checkboxes"]}>
                  <div>
                    <label
                      htmlFor=""
                      onClick={() => setAgreementPopup(true)}
                      style={{ cursor: "pointer", color: "blue" }}
                    >
                      İştirak şərtləri və razılıq mətni
                    </label>
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      onChange={() => handleAgreement()}
                      checked={agreement}
                    />
                  </div>
                  {agreementPopup ? (
                    <AgreementPopup handleChange={setAgreementPopup}>
                      Qeydiyyat üçün tərəfimdən təqdim olunan məlumatların
                      doğruluğunu, həmçinin festivalın keçirilmə qaydaları və
                      şərtləri ilə tanış olduğumu təsdiq edirəm. Festival
                      iştirakçısının iştirakı çərçivəsində çəkilən foto və
                      videomateriallarının ictimailəşdirilməsinə etiraz etmirəm
                      və məlumatların düzgünlüyünə dair şəxsən məsuliyyət
                      daşıyıram.
                    </AgreementPopup>
                  ) : null}

                  {thirdPageError ? (
                    <>
                      <AgreementPopup
                        handleChange={setThirdPageError}
                        error={true}
                      >
                        {thirdPageError}
                      </AgreementPopup>
                    </>
                  ) : null}
                </div>
                <div className={styles["next-container"]}>
                  <button
                    type="button"
                    className={styles["next"]}
                    onClick={() => {
                      handleInformation();
                    }}
                    disabled={!agreement}
                  >
                    Qeydiyyatı təsdiq edirəm
                  </button>
                </div>
              </form>
              {finPopup ? (
                <InfoPopup
                  handleChange={setFinPopup}
                  photos={[FinNew, FinAndSerialOld]}
                />
              ) : null}
              {serialPopup ? (
                <InfoPopup
                  handleChange={setSerialPopup}
                  photos={[SerialNew, FinAndSerialOld]}
                />
              ) : null}
              {searchError ? (
                <AgreementPopup handleChange={setSearchError}>
                  {searchError}
                </AgreementPopup>
              ) : null}
            </div>
          </>
        ) : null}

        {page === 4 ? (
          <>
            <>
              <Wrapper>
                <Link to={"/"} className={styles["go-back"]}>
                  <BackArrow />
                  <p>Geri qayıt</p>
                </Link>
              </Wrapper>
              <Wrapper>
                <div className={styles["done-container"]}>
                  <div className={styles["done"]}>
                    <img src={Done} alt="done" />
                    <h3>
                      Qeydiyyat uğurla tamamlanmışdır. İştirakçının qeydiyyat
                      kodu:
                      <br />
                      {code}
                    </h3>
                    <button
                      className={styles["copy_button"]}
                      onClick={() => navigator.clipboard.writeText(code)}
                    >
                      Kodu kopyala
                    </button>
                    <h2 style={{ fontWeight: 900 }}>{postResponse}</h2>
                    <h3>
                      İştirakçının kodu festival çərçivəsində dəyişilməz olaraq
                      qalacaqdır. Xahiş edirik kodu itirməyəsiniz.
                    </h3>
                  </div>
                </div>
              </Wrapper>
            </>
          </>
        ) : null}
      </section>
    </>
  );
};
export default PersonalRegistrationTest;
