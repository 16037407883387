import axios from "../index";

const sendFirstPage = data => {
  return axios.post('collective-registration-first-step', data, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  })
}

const sendKid = data => {
  return axios.post('collective-registration-second-step', data, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  })
}
const sendSecondPage = data => {
  return axios.post('collective-registration-check', data, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  })
}

const sendThirdPage = data => {
  return axios.post('collective-registration-third-step', data, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  })
}


export default { sendFirstPage, sendKid, sendSecondPage, sendThirdPage }