import axios, { imesApi } from "../index";

const getArtSchools = (params) => {
  // return imesApi.get('external/students', { params });
}
const getEducationSchools = (params) => {
  return axios.get('education-schools', { params });
}
const getEducationSchoolsTypes = () => {
  return axios.get('education-schools-types');
}
export default { getArtSchools, getEducationSchoolsTypes, getEducationSchools }