import axios from "../index";

const sendCollectiveData = data => {
  return axios.post('collective-registration', data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    timeout:  10000
  })
}

export default { sendCollectiveData }