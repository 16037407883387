import React, { useState } from "react";
import styles from "./index.module.css";

const InfoPopup = ({ handleChange, photos, tabs = true }) => {
  const [regType, setRegType] = useState(1);
  return (
    <>
      <div
        className={styles["overlay"]}
        onClick={() => handleChange(false)}
      ></div>
      <div className={styles["fl"]}>
        <div className={styles["container"]}>
          {tabs ? (
            <div className={styles["tabs"]}>
              <button
                className={
                  styles[
                    `${regType == 1 ? "tab-buttons-active" : "tab-buttons"}`
                  ]
                }
                onClick={() => setRegType(1)}
              >
                Yeni
              </button>
              <button
                className={
                  styles[
                    `${regType == 2 ? "tab-buttons-active" : "tab-buttons"}`
                  ]
                }
                onClick={() => setRegType(2)}
              >
                Köhnə
              </button>
            </div>
          ) : null}

          <div className={styles["container-children"]}>
            {regType == 1 ? (
              <img src={photos[0]} alt="" />
            ) : (
              <img src={photos[1]} alt="" />
            )}
          </div>
          <div className={styles["next-container"]}>
            <button
              type="button"
              className={styles["next"]}
              onClick={() => handleChange(false)}
            >
              Oldu
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoPopup;
