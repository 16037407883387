import Home from "./pages/home";
import PersonalRegistration from "./pages/personalRegistration";
import Registration from "./pages/registration";
import CollectiveRegistration from "./pages/collectiveRegistration";
import RegistrationCheck from "./pages/registrationCheck";
import NotFound from "./pages/NotFound";
import RegistrationCheckTest from "./pages/registrationCheckTest";
import RegistrationCheckDetails from "./pages/registrationCheckDetails";
import Test from "./pages/personalRegistration/test";
import TestRegistration from "./pages/registration/test_registration";
import PersonalRegistrationTest from "./pages/personalRegistration/test";
import CollectiveRegistrationTest from "./pages/collectiveRegistration/test_collective";
const routeItem = (id, title, path, component) => {
  return {
    id,
    title,
    path,
    component,
  };
};

const routes = {
  home: routeItem(1, "Home", "/", Home),
  // personalRegistration: routeItem(
  //   2,
  //   "PersonalRegistration",
  //   "/personal-registration",
  //   PersonalRegistration
  // ),
  personalRegistration: routeItem(
    2,
    "PersonalRegistration",
    "/test-registration",
    PersonalRegistrationTest
  ),
  // registration: routeItem(3, "Registration", "/registration", Registration),
  registration: routeItem(30, "RegistrationTest", "/registration-test", TestRegistration),
  // collectiveRegistration: routeItem(
  //   4,
  //   "CollectiveRegistration",
  //   "/collective-registration",
  //   CollectiveRegistration
  // ),
  collectiveRegistration: routeItem(
    4,
    "CollectiveRegistration",
    "/test-collective",
    CollectiveRegistrationTest
  ),
  registrationCheck: routeItem(
    5,
    "RegistrationCheck",
    "/registration-check",
    RegistrationCheck
  ),
  registrationCheckTest: routeItem(
    6,
    "RegistrationCheckTest",
    "/registration-check-test",
    RegistrationCheckTest
  ),
  RegistrationCheckDetails: routeItem(
    7,
    "RegistrationCheckDetails",
    "/registration-check-test/:userId",
    RegistrationCheckDetails
  ),
  // Test: routeItem(7, "Test", "/test", Test),
  not_found: routeItem(404, "404", "*", NotFound),
};

const routeArr = Object.values(routes);

export { routes, routeArr };
