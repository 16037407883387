import axios, { imesApi } from "../index";

const sendPersonalData = data => {
  return axios.post('personal-registration', data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    timeout: 10000
  })
}

const getPersonalData = (params) => {
  return imesApi.get('external/students', { params })
}

export default { sendPersonalData, getPersonalData }