import axios from "../index";

const getNominations = () => {
  return axios.get('personal-nominations');
}

const getCollectiveNominations = () => {
  return axios.get('collective-nominations');
}

export default { getNominations, getCollectiveNominations }