import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./index.module.css";
import { BackArrow } from "../../icons";
import Done from "../../icons/done-icon.png";
import Wrapper from "../../components/UI/wrapper";
import Loading from "../../components/loading";
import Nominations from "../../api/nominations";
import Regions from "../../api/regions";
import Schools from "../../api/schools";
import SearchableDropdown from "../../components/searchableDropdown";
import collective from "../../api/collective";
import AgreementPopup from "../../components/agreementPopup";
import InfoPopup from "../../components/infoPopup";
import FinAndSerialOld from "../../assets/images/documents/Fin_and_serial_old.jpeg";
import FinNew from "../../assets/images/documents/Fin_new.png";
import SerialNew from "../../assets/images/documents/serial_new.png";
import BirthCertImg from "../../assets/images/documents/birth_cert.png";
import searchImg from "../../assets/images/linear.svg";
import collectiveValidation from "../../api/collectiveValidation";
import { DatePicker, Input, Select } from "antd";
import { getAllCities } from "../../api/cities";
import instance, { imesApi } from "../../api";
import dayjs from "dayjs";
import personal from "../../api/personal";

const dateFormat = "YYYY-MM-DD";

const prefixes = [
  {
    value: "050",
    label: "050",
  },
  {
    value: "051",
    label: "051",
  },
  {
    value: "010",
    label: "010",
  },

  {
    value: "055",
    label: "055",
  },
  {
    value: "099",
    label: "099",
  },
  {
    value: "070",
    label: "070",
  },
  {
    value: "077",
    label: "077",
  },
  {
    value: "060",
    label: "060",
  },
];

const CollectiveRegistrationTest = () => {
  const [page, setPage] = useState(1);
  const [isSearched, setIsSearched] = useState(false)
  const [code, setCode] = useState(null)
  const [collectiveRegion, setCollectiveRegion] = useState(null)
  const [awardsVal, setAwardsVal] = useState([]);
  const [nameVal, setNameVal] = useState(null);
  const [surnameVal, setSurnameVal] = useState(null);
  const [patronymicVal, setPatronymicVal] = useState(null);
  const [dateVal, setDateVal] = useState(null);
  const [finRadio, setFinRadio] = useState(1);
  const [serialRadio, setSerialRadio] = useState(1);
  const [finVal, setFinVal] = useState(null);
  const [serialVal, setSerialVal] = useState(null);
  const [birthCert, setBirthCert] = useState(null);
  const [regAddress, setRegAddress] = useState(null);
  const [factAddress, setFactAddress] = useState(null);
  const [school, setSchool] = useState(null);
  const [artSchool, setArtSchool] = useState(2);
  const [artSchoolDetails, setArtSchoolDetails] = useState(null);
  const [city, setCity] = useState(null);
  const [nomination, setNomination] = useState(null);
  const [image, setImage] = useState(null);
  const [imageLink, setImageLink] = useState(null);
  const [asanImage, setAsanImage] = useState(null)
  const [awardVal, setAwardVal] = useState(null);
  const [specialSchool, setSpecialSchool] = useState(null);
  const [parentsName, setParentsName] = useState(null);
  const [parentsSurname, setParentsSurname] = useState(null);
  const [parentsPatronymic, setParentsPatronymic] = useState(null);
  const [parentsFin, setParentsFin] = useState(null);
  const [parentsSerialRadio, setParentsSerialRadio] = useState(1);
  const [parentsSerial, setParentsSerial] = useState(null);
  const [parentsEmail, setParentsEmail] = useState(null);
  const [rules, setRules] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [collectiveName, setCollectiveName] = useState(null);
  const [collectiveCityId, setCollectiveCityId] = useState(null)
  const [collectiveCitySelectList, setCollectiveCitySelectList] = useState([])
  const [collectiveFoundationYear, setCollectiveFoundationYear] = useState(null);
  const [children, setChildren] = useState([]);
  const [agreementPopup, setAgreementPopup] = useState(false);
  const [postResponse, setPostResponse] = useState(null);
  const [finPopup, setFinPopup] = useState(false);
  const [serialPopup, setSerialPopup] = useState(false);
  const [birthCertPopup, setBirthCertPopup] = useState(false);
  const [genderRadio, setGenderRadio] = useState("MALE");
  const [disabled, setDisabled] = useState(true);
  const [directorDisabled, setDirectorDisabled] = useState(true);
  const [imageType, setImageType] = useState(2)
  const [studentFromAsan, setStudentFromAsan] = useState(null)
  const [imesUser, setImesUser] = useState(null)
  const [artEducation, setArtEducation] = useState("")
  const [parentFromAsan, setParentFromAsan] = useState(null)
  const [parentsMobile, setParentsMobile] = useState({
    first: { prefix: prefixes[0].value, number: "" },
    second: { prefix: prefixes[0].value, number: "" },
  });

  /*Loading */
  const [loading, setLoading] = useState(false);

  /*Errors*/
  const [imageError, setImageError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [ageError, setAgeError] = useState(null);
  const [regAddressError, setRegAddressError] = useState(null);
  const [factAddressError, setFactAddressError] = useState(null);
  const [finError, setFinError] = useState(null);
  const [birthCertError, setBirthCertError] = useState(null);
  const [serialError, setSerialError] = useState(null);
  const [cityError, setCityError] = useState(null);
  const [schoolError, setSchoolError] = useState(null);
  const [nominationError, setNominationError] = useState(null);
  const [parentsNameError, setParentsNameError] = useState(null);
  const [parentsFinError, setParentsFinError] = useState(null);
  const [parentsSerialError, setParentsSerialError] = useState(null);
  const [collectiveNameError, setCollectiveNameError] = useState(null);
  const [collectiveCityError, setCollectiveCityError] = useState(null)
  const [collectiveFoundationYearError, setCollectiveFoundationYearError] =
    useState(null);
  const [parentsMobileError, setParentsMobileError] = useState({
    first: "",
    second: "",
  });
  const [childrenError, setChildrenError] = useState(null);
  const [firstPageError, setFirstPageError] = useState(null);
  const [secondPageError, setSecondPageError] = useState(null);
  const [thirdPageError, setThirdPageError] = useState(null);
  const [specialSchoolError, setSpecialSchoolError] = useState(null);
  const [artEducationError, setArtEducationError] = useState(null)
  const [searchError, setSearchError] = useState(null)
  const [parentsEmailError, setParentsEmailError] = useState(null)
  const [awardsError, setAwardsError] = useState(null)

  /* api variables */

  const [nominationsSelect, setNominationsSelect] = useState([]);
  const [regionsSelect, setRegionsSelect] = useState([]);
  const [artSchoolsSelect, setArtSchoolsSelect] = useState([]);

  let error;

  const handleAwardAdd = () => {
    if (awardVal?.trim()?.length > 0) {
      setAwardsVal((prev) => [...prev, awardVal]);
      setAwardVal("");
    }
  };
  const handleAwardChange = (onChangeValue, i) => {
    const inputdata = [...awardsVal];
    inputdata[i] = onChangeValue.target.value;
    setAwardsVal(inputdata);
  };
  const handleAwardDelete = (i) => {
    const deletVal = [...awardsVal];
    deletVal.splice(i, 1);
    setAwardsVal(deletVal);
  };

  const handleName = (e) => {
    setNameError(null);
    setNameVal(e.target.value);
  };
  const handleSurname = (e) => {
    setNameError(null);
    setSurnameVal(e.target.value);
  };
  const handlePatronymic = (e) => {
    setNameError(null);
    setPatronymicVal(e.target.value);
  };

  const handleDate = (e) => {
    setAgeError(null);
    setDateVal(e);
  };

  const handleFin = (e) => {
    setFinError(null);
    setFinVal(e.target.value);
  };
  const handleBirthCert = (e) => {
    setBirthCertError(null);
    setBirthCert(e.target.value);
  };

  const handleRegAddress = (e) => {
    setRegAddressError(null);
    setRegAddress(e.target.value);
  };
  const handleFactAddress = (e) => {
    setFactAddressError(null);
    setFactAddress(e.target.value);
  };

  const handleSchool = (e) => {
    setSchoolError(null);
    setSchool(e.target.value);
  };
  const handleArtSchoolDetails = (e) => {
    setArtSchoolDetails(e.target.value);
  };
  const handleAwardsVal = (e) => {
    setAwardVal(e.target.value);
  };
  const handleCity = (id) => {
    setCityError(null);
    setCity(id);
  };

  const handleParentsMobile = (e, type) => {
    setParentsMobileError((prev) => ({ ...prev, [type]: "" }));
    let data = {
      prefix: parentsMobile[type]?.prefix,
      number: e.target.value,
    };
    setParentsMobile((prev) => ({ ...prev, [type]: data }));
  };

  const handleParentsMobilePrefix = (e, type) => {
    setParentsMobileError((prev) => ({ ...prev, [type]: "" }));
    let data = {
      prefix: e.target.value,
      number: parentsMobile[type]?.number,
    };
    setParentsMobile((prev) => ({ ...prev, [type]: data }));
  };

  const handleNomination = (e) => {
    setNominationError(null);
    setNomination(e);
  };
  const handleImage = (e) => {
    setImageError(null);
    setImageLink(URL.createObjectURL(e.target.files[0]));
    // setImage([...image, e.target.files[0]]);
    // let inputdata;
    // if (image) {
    //   inputdata = [...image];
    //   inputdata.push(e.target.files[0]);
    // } else {
    //   inputdata = [e.target.files[0]];
    // }
    setImage(e.target.files[0]);
  };
  const handleSerial = (e) => {
    setSerialError(null);
    setSerialVal(e.target.value);
  };
  const handleSpecialSchool = (val) => {
    setSpecialSchool(val);
  };
  const handleParentsName = (e) => {
    setParentsNameError(null);
    setParentsName(e.target.value);
  };
  const handleParentsSurname = (e) => {
    setParentsNameError(null);
    setParentsSurname(e.target.value);
  };
  const handleParentsPatronymic = (e) => {
    setParentsNameError(null);
    setParentsPatronymic(e.target.value);
  };
  const handleParentsFin = (e) => {
    setParentsFinError(null);
    setParentsFin(e.target.value);
  };
  const handleParentsSerial = (e) => {
    setParentsSerialError(null);
    setParentsSerial(e.target.value);
  };
  const handleParentsEmail = (e) => {
    setParentsEmail(e.target.value);
  };
  const handleRules = () => {
    setRules(!rules);
  };
  const handleAgreement = () => {
    setAgreement(!agreement);
  };
  const handleCollectiveName = (e) => {
    setCollectiveNameError(null);
    setCollectiveName(e.target.value);
  };
  const handleCollectiveFoundationYear = (e) => {
    setCollectiveFoundationYearError(null);
    setCollectiveFoundationYear(e.target.value);
  };
  const artSchoolPlaceholder = () => {
    if (specialSchool) return specialSchool[1];
  };
  const deleteChild = (i) => {
    const deletVal = [...children];
    deletVal.splice(i, 1);
    setChildren(deletVal);
  };

  const handleMultiChildren = () => {
    // let age = getAge(dateVal);
    let existChild = children.find(item => item.fin_code === studentFromAsan?.fin_code)
    if (existChild) {
      setSecondPageError("İştirakçı kollektiv daxilində mövcuddur")
      return
    }
    error = 0;
    setChildrenError(null);
    if (!image) {
      setImageError("Foto yüklənməyib");
      error++;
    }
    if (!nameVal || !surnameVal || !patronymicVal) {
      setNameError("İştirakçı adı, soyadı və ya ata adı doldurulmayıb");
      error++;
    }
    if (!dateVal) {
      setAgeError("Doğum tarixi daxil edilməyib");
      error++;
    }
    if (!regAddress) {
      setRegAddressError("Qeydiyyat ünvanı daxil edilməyib");
      error++;
    }
    if (!factAddress) {
      setFactAddressError("Faktiki yaşayış ünvanı daxil edilməyib");
      error++;
    }
    if (finRadio == 1) {
      if (!finVal) {
        setFinError("Fin kod daxil edilməyib");
        error++;
      }
    }
    if (finRadio == 2) {
      if (!birthCert) {
        setBirthCertError("Doğum haqqında şəhadətnamə daxil edilməyib");
        error++;
      }
    }
    if (finRadio == 1) {
      if (!serialVal) {
        setSerialError("Şəxsiyyət vəsiqəsinin seriya nömrəsi daxil edilməyib");
        error++;
      }
    }
    if (artSchool == 1) {
      if (!artEducation) {
        setArtEducationError(
          "Xüsusi incəsənət təhsili düzgün daxil edilməyib"
        );
      }
    }
    if (image.size > 5242881) {
      setImageError("Foto həcmi 5MB artıq ola bilməz");
      error++;
    }
    if (error === 0) {
      validateKid();
    }
  };
  const handleInformation = () => {
    error = 0;
    if (page === 1) {
      if (!collectiveName) {
        setCollectiveNameError("Kollektivin adı daxil edilməyib");
        error++;
      }
      if (!collectiveFoundationYear) {
        setCollectiveFoundationYearError("Yarandığı il daxil edilməyib");
        error++;
      }
      if (!collectiveRegion) {
        setCityError("Şəhər/rayon daxil edilməyib");
        error++;
      }
      if (!collectiveCityId) {
        setCollectiveCityError("Aid olduğu şəhər daxil edilməyib")
        error++
      }
      if (!nomination) {
        setNominationError("Nominasiya daxil edilməyib");
        error++;
      }
      if (awardsError) {
        error++
      }
      if (error == 0) {
        validateFirstPage();
      }
    }
    if (page === 2) {
      if (error === 0) {
        validateSecondPage();
      }
    }
    if (page === 3) {
      if (!parentsName || !parentsSurname || !parentsPatronymic) {
        setParentsNameError("Ad, soyad və ya ata adı daxil edilməyib");
        error++;
      }
      if (!parentsFin) {
        setParentsFinError("Fin kod daxil edilməyib");
        error++;
      }
      if (!parentsSerial) {
        setParentsSerialError(
          "Şəxsiyyət vəsiqəsinin seriya nömrəsi daxil edilməyib"
        );
        error++;
      }
      if (!parentsMobile) {
        setParentsMobileError("Əlaqə nömrəsi daxil edilməyib");
        error++;
      }
      if (error == 0) {
        validateThirdPage();
      }
    }
  };

  async function handleSend() {
    const infoObj = {
      collective_city_id: collectiveCityId,
      collective_mn_region_id: collectiveRegion,
      collective_nomination_id: nomination,
      awards_name: awardVal?.trim() ? [awardVal, ...awardsVal] : awardsVal,
      collective_name: collectiveName,
      collective_created_date: collectiveFoundationYear,

      // photo: image,
      key: children,
      director_name: parentFromAsan?.director_name || parentsName,
      director_surname: parentFromAsan?.director_surname || parentsSurname,
      director_patronymic: parentFromAsan?.director_patronymic || parentsPatronymic,
      director_fin_code: parentFromAsan?.director_fin_code || parentsFin,
      director_serial_number: parentFromAsan?.director_serial_number || parentsSerial,
      directors_card_old_or_new: parentFromAsan?.directors_card_old_or_new || parentsSerialRadio,
      first_prefix: parentsMobile.first.prefix,
      second_prefix: parentsMobile.second.prefix,
      first_phone_number: parentsMobile.first.number,
      second_phone_number: parentsMobile.second.number,
      email: parentsEmail,
    };
    try {
      setLoading(true)
      const res = await collective.sendCollectiveData(infoObj)
      if (res.status === 201) {
        setPostResponse(res.data.message)
        setCode(res.data.message);
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  async function validateFirstPage() {

    setFirstPageError(null);
    if (error == 0) {
      const firstPage = {
        collective_name: collectiveName,
        collective_created_date: collectiveFoundationYear,
        collective_mn_region_id: collectiveRegion,
        collective_nomination_id: nomination,
        collective_city_id: collectiveCityId,
        awards_name: awardVal?.trim() ? [awardVal, ...awardsVal] : awardsVal
      };
      setLoading(true)
      const data = await collectiveValidation
        .sendFirstPage(firstPage)
        .then((response) => {
          if (response.data.message == "OK") {
            setPage(page + 1);
          }
        })
        .catch((error) => {
          setFirstPageError(error.response.data.message);
          setCollectiveFoundationYearError(error.response.data.errors.collective_created_date?.join(". "))
        })
        .finally(() => setLoading(false));
    }
  }

  async function validateKid() {
    if (!isSearched) {
      setSecondPageError("Seriya nömrəsi və fin kod ilə axtarış edin.")
      return
    } else {
      setSecondPageError(null);
    }

    const tempChildren = children;
    const childData = new FormData()
    childData.append('photo', image)
    childData.append('name', studentFromAsan?.name || nameVal)
    childData.append('surname', studentFromAsan?.surname || surnameVal)
    childData.append('patronymic', studentFromAsan?.patronymic || patronymicVal)
    childData.append('birth_date', studentFromAsan?.birth_date || dayjs(dateVal).format(dateFormat))
    childData.append('fin_code', studentFromAsan?.fin_code || finVal)
    childData.append('registration_address', studentFromAsan?.registration_address || regAddress)
    childData.append('live_address', factAddress)
    childData.append('serial_number', studentFromAsan?.serial_number || serialVal)
    childData.append('card_old_or_new', studentFromAsan?.card_old_or_new || serialRadio)
    childData.append('art_type', artSchool)
    childData.append('art_education', artSchool == 1 ? artEducation : artSchoolDetails)
    childData.append('gender', studentFromAsan?.gender || genderRadio)
    childData.append('mn_region_id', city)
    childData.append('image_type', imageType)

    let child;
    child = {
      photo: image,
      name: studentFromAsan?.name || nameVal,
      surname: studentFromAsan?.surname || surnameVal,
      patronymic: studentFromAsan?.patronymic || patronymicVal,
      birth_date: studentFromAsan?.birth_date || dayjs(dateVal).format(dateFormat),
      fin_code: studentFromAsan?.fin_code || finVal,
      registration_address: studentFromAsan?.registration_address || regAddress,
      live_address: factAddress,
      serial_number: studentFromAsan?.serial_number || serialVal,
      card_old_or_new: studentFromAsan?.card_old_or_new || serialRadio,
      art_type: artSchool,
      art_education: artSchool == 1 ? artEducation : artSchoolDetails,
      gender: studentFromAsan?.gender || genderRadio,
      image_type: imageType,
      mn_region_id: city
    };

    if (error == 0) {
      setLoading(true)
      const data = await collectiveValidation
        .sendKid(childData)
        .then((response) => {
          if (response.data.message == "OK") {
            // setPage(page + 1);
            tempChildren.push(child);
            setChildren(tempChildren);
            setImage(null);
            // image[image.length];
            setImageLink(null);
            setAsanImage(null)
            setNameVal("");
            setSurnameVal("");
            setPatronymicVal("");
            setCity(null)
            setImageType(2)
            setDateVal("");
            setFinVal("");
            setBirthCert("");
            setRegAddress("");
            setFactAddress("");
            setSerialVal("");
            setSerialRadio(1);
            setArtSchool(2);
            setSpecialSchool("");
            setFinRadio(1);
            setGenderRadio("MALE");

            setArtEducation(null)
            setArtSchoolDetails(null)
          }
        })
        .catch((error) => {
          setSecondPageError(error.response.data.message);
        })
        .finally(() => setLoading(false))
    }
  }
  async function validateSecondPage() {
    // 
    setSecondPageError(null);
    if (error === 0) {
      const secondPage = {
        nomination_id: nomination,
        children_count: children.length,
      };
      setLoading(true)
      const data = await collectiveValidation
        .sendSecondPage(secondPage)
        .then((response) => {
          if (response.data.message == "OK") {
            setPage(page + 1);

          }
        })
        .catch((error) => {
          setSecondPageError(error.response.data.message);
        })
        .finally(() => setLoading(false))
    }
  }
  async function validateThirdPage() {
    setThirdPageError(null);
    if (error === 0) {
      const ThirdPage = {
        director_name: parentFromAsan?.name || parentsName,
        director_surname: parentFromAsan?.surname || parentsSurname,
        director_patronymic: parentFromAsan?.patronymic || parentsPatronymic,
        director_fin_code: parentFromAsan?.fin_code || parentsFin,
        director_serial_number: parentFromAsan?.serial_number || parentsSerial,
        directors_card_old_or_new: parentFromAsan?.card_old_or_new || parentsSerialRadio,
        first_prefix: parentsMobile.first.prefix,
        second_prefix: parentsMobile.second.prefix,
        first_phone_number: parentsMobile.first.number,
        second_phone_number: parentsMobile.second.number,
        email: parentsEmail
      };
      setLoading(true)
      const data = await collectiveValidation
        .sendThirdPage(ThirdPage)
        .then((response) => {
          if (response.data.message == "OK") {
            handleSend();
            setPage(page + 1);
          }
        })
        .catch((error) => {
          setThirdPageError(error.response.data.message);
          setParentsEmailError(error.response.data.errors.email)
          if (error.response.data.errors.first_phone_number) {
            setParentsMobileError(prev => ({ ...prev, first: error.response.data.errors.first_phone_number?.join(". ") }))
          } else if (error.response.data.errors.second_phone_number) {
            setParentsMobileError(prev => ({ ...prev, second: error.response.data.errors.second_phone_number?.join(". ") }))
          }
        })
        .finally(() => setLoading(false))
    }
  }

  function getAge(birthDateString) {
    var today = new Date();
    var birthDate = new Date(birthDateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const setSearchFin = async () => {
    try {
      getPersonalDataFromImes()
      setSerialError(null)
      setFinError(null)
      setNameError(null)
      setImageError(null)
      setRegAddressError(null)
      setFactAddressError(null)
      setAgeError(null)
      let obj = {
        fin_code: finVal,
        serial_number: serialVal,
        card_old_or_new: serialRadio,
      };
      if (!obj.serial_number) {
        setSerialError('Seriya nömrəsi daxil edilməyib')
        return
      }
      if (!obj.fin_code) {
        setFinError('Fin kod daxil edilməyib')
        return
      }
      if (obj.card_old_or_new && obj.serial_number && obj.fin_code) {
        setIsSearched(true)
        const res = await instance.get('get-collective-data', { params: obj })
        const user = res.data.data
        setDateVal(dayjs(user?.birth_date, dateFormat));
        setNameVal(user.name)
        setSurnameVal(user.surname)
        setPatronymicVal(user.patronymic)
        setRegAddress(user.registration_address)
        setGenderRadio(user.gender)
        setImage(user.photo);
        setAsanImage(user.photo);
        if (user.photo) {
          setImageType(1);
          setImageError(null);
        } else {
          setImageType(2);
        }
        setStudentFromAsan(user);
        setDisabled(true)
      }
    } catch (error) {
      setStudentFromAsan(null)
      if (error.response.data.code === 2) {
        setDisabled(false);
      }
      setSecondPageError(error.response.data.message)
      setSerialError(error.response.data.errors?.serial_number[0])
      setFinError(error.response.data.errors?.fin_code[0])
      setDateVal(null);
      setNameVal(null)
      setSurnameVal(null)
      setPatronymicVal(null)
      setRegAddress(null)
      setGenderRadio("MALE")
      setImage(null);
      setAsanImage(null);
    }
  }

  const getPersonalDataFromImes = async () => {
    try {
      const res = await personal.getPersonalData({
        pin: finVal,
        serial_number: serialVal,
      });
      let obj = {
        custom_code: res.data.custom_code,
        school_name: res.data.data.name,
      };
      setArtEducation(obj.school_name)
      if (obj.custom_code === 2) {
        setArtSchool(1);
      } else {
        setArtSchool(2);
      }
      setImesUser(obj);
    } catch (error) {
      setImesUser(null)
      console.log(error);
    }
  };

  const searchParent = async () => {
    try {
      const obj = {
        director_fin_code: parentsFin,
        director_serial_number: parentsSerial,
        directors_card_old_or_new: parentsSerialRadio,
      };
      if (!obj.director_serial_number) {
        setParentsSerialError('Seriya nömrəsi daxil edilməyib')
        return
      }
      if (!obj.director_fin_code) {
        setParentsFinError('Fin kod daxil edilməyib')
        return
      }
      if (obj.director_fin_code && obj.director_serial_number && obj.directors_card_old_or_new) {
        const res = await instance.get("/collective-director-data", {
          params: obj,
        });
        let user = res.data.data;
        setParentsName(user.director_name);
        setParentsSurname(user.director_surname);
        setParentsPatronymic(user.director_patronymic);
        setParentFromAsan(user);
        setDirectorDisabled(true)
      }
    } catch (error) {
      setParentsName(null)
      setParentsSurname(null)
      setParentsPatronymic(null)
      setParentFromAsan(null)
      if (error.response.data.code === 2) {
        setDirectorDisabled(false);
      }
      setThirdPageError(error.response.data?.message)
      setParentsSerialError(error.response.data?.errors?.director_serial_number);
      setParentsFinError(error.response.data?.errors?.director_fin_code);
      setSearchError(error.response.data?.message);
      if (error.response.data?.errors?.first_phone_number) {
        setParentsMobileError(prev => ({ ...prev, first: error.response.data?.errors?.first_phone_number?.join(". ") }))
      } else if (error.response.data.errors.second_phone_number) {
        setParentsMobileError(prev => ({ ...prev, second: error.response.data?.errors?.second_phone_number?.join(". ") }))
      }
    }
  };

  useEffect(() => {
    async function fetchNominations() {
      const { data } = await Nominations.getCollectiveNominations();
      setNominationsSelect(data);
    }
    async function fetchRegions() {
      const { data } = await Regions.getRegions();
      setRegionsSelect(data);
    }
    async function fetchAllCities() {
      const { data } = await getAllCities();
      setCollectiveCitySelectList(data)
    }

    if (page === 1) {
      fetchNominations();
      fetchRegions();
      fetchAllCities()
    } else if (page === 2) {
      // setDisabled(true)
    } else if (page === 2) {
      // setDisabled(true)
    }


  }, [page]);

  return (
    <>
      <section className={styles["section"]}>
        {loading && <Loading />}
        {page === 1 ? (
          <>
            <Wrapper>
              <Link to={"/registration-test"} className={styles["go-back"]}>
                <BackArrow />
                <p>Geri qayıt</p>
              </Link>
            </Wrapper>
            <div className={styles["wrapper"]}>
              <h2 className={styles["reg"]}>Kollektiv qeydiyyat</h2>
              <div className={styles["progress"]}>
                <div className={styles["progressed"]}></div>
                <div className={styles["step1"]}>1</div>
                <div className={styles["step2"]}>2</div>
                <div className={styles["step3"]}>3</div>
              </div>
              <div className={styles["progress-name"]}>
                <p>Kollektiv məlumatlar</p>
                <p className={styles["inactive-name"]}>Üzvləri daxil edin</p>
                <p className={styles["inactive-name"]}>Kollektiv rəhbər</p>
              </div>
              <form action="">
                <div className={styles["collective-container"]}>
                  <div className={`${styles.input_container} ${styles.collective_name}`}>
                    <label htmlFor="">Kollektivin adı</label>
                    <input
                      type="text"
                      placeholder="Kollektivin adı"
                      onChange={(e) => handleCollectiveName(e)}
                      value={collectiveName}
                    />
                    {collectiveNameError ? (
                      <p className={styles["error"]}>
                        {collectiveNameError}
                      </p>
                    ) : null}
                  </div>

                  <div className={`${styles.input_container} ${styles.collective_year}`}>
                    <label htmlFor="">Yarandığı il</label>
                    <input
                      type="number"
                      min="0"
                      inputmode="numeric"
                      placeholder="Yarandığı il"
                      onChange={(e) => handleCollectiveFoundationYear(e)}
                      value={collectiveFoundationYear}
                    />
                    {collectiveFoundationYearError && (
                      <p className={styles["error"]}>
                        {collectiveFoundationYearError}
                      </p>
                    )}
                  </div>
                </div>

                <div className={styles["collective_city"]}>
                  <label htmlFor="city">Müraciət etdiyi şəhər/rayon</label>
                  <Select
                    options={regionsSelect.map(item => ({
                      value: item.id,
                      label: item.name
                    }))}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    placeholder="Müraciət etdiyi şəhər/rayon"
                    showSearch
                    value={collectiveRegion}
                    size="large"
                    id="city"
                    onChange={id => {
                      setCollectiveRegion(id)
                      setCityError(null)
                    }}
                  />
                </div>
                {cityError ? (
                  <p className={styles["error"]}>{cityError}</p>
                ) : null}
                <div className={styles["collective_city"]}>
                  <label htmlFor="collective_city_id">Faktiki fəaliyyət göstərdiyi şəhər</label>
                  <Select
                    id="collective_city_id"
                    showSearch
                    options={collectiveCitySelectList.map(item => ({
                      value: item.id,
                      label: item.city_name,
                    }))}
                    value={collectiveCityId}
                    onChange={id => {
                      setCollectiveCityId(id)
                      setCollectiveCityError(null)
                    }}
                    size="large"
                    placeholder="Şəhər"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                  {collectiveCityError && <p className={styles["error"]}>{collectiveCityError}</p>}
                </div>

                <div className={styles["collective_city"]}>
                  <label htmlFor="nomination">Müraciət etdiyi nominasiya</label>
                  <Select
                    id="nomination"
                    size="large"
                    placeholder="Müraciət etdiyi nominasiya"
                    value={nomination}
                    onChange={handleNomination}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={nominationsSelect.map(item => ({
                      value: item.id,
                      label: item.name + `  (${item?.message})`
                    }))}
                  />
                </div>
                {nominationError ? (
                  <p className={styles["error"]}>{nominationError}</p>
                ) : null}
                <div>
                  <div className={styles["awards"]}>
                    <label htmlFor="">Təltiflər</label>
                    <input
                      type="text"
                      placeholder="Təltif"
                      onChange={(e) => handleAwardsVal(e)}
                      style={{ marginBottom: 10 + "px" }}
                      value={awardVal}
                    />
                    {awardsVal.map((data, i) => {
                      return (
                        <div className={styles["added-award"]}>
                          <input
                            value={data}
                            onChange={(e) => handleAwardChange(e, i)}
                            placeholder="Təltif"
                          />
                          <button
                            type="button"
                            onClick={() => handleAwardDelete(i)}
                          >
                            Sil
                          </button>
                        </div>
                      );
                    })}
                  </div>
                  <div className={styles["add-container"]}>
                    <button
                      type="button"
                      className={styles["add"]}
                      onClick={() => handleAwardAdd()}
                    >
                      Əlavə et
                    </button>
                  </div>
                </div>
                <div className={styles["next-container"]}>
                  <button
                    type="button"
                    className={styles["next"]}
                    onClick={() => handleInformation()}
                  >
                    Növbəti
                  </button>
                </div>
              </form>
              {firstPageError ? (
                <>
                  <AgreementPopup
                    handleChange={setFirstPageError}
                    error={true}
                  >
                    {firstPageError}
                  </AgreementPopup>
                </>
              ) : null}
            </div>
          </>
        ) : null}

        {page === 2 ? (
          <>
            <Wrapper>
              <div onClick={() => setPage(1)} className={styles["go-back"]}>
                <BackArrow />
                <p>Geri qayıt</p>
              </div>
            </Wrapper>
            <div className={styles["wrapper"]}>
              <h2 className={styles["reg"]}>Kollektiv qeydiyyat</h2>
              <div className={styles["progress"]}>
                <div
                  className={styles["progressed"]}
                  style={{ width: 66 + "%" }}
                ></div>
                <div className={styles["step1"]}>1</div>
                <div className={styles["step2-2"]}>2</div>
                <div className={styles["step3"]}>3</div>
              </div>
              <div className={styles["progress-name"]}>
                <p>Kollektiv məlumatlar</p>
                <p>Üzvləri daxil edin</p>
                <p className={styles["inactive-name"]}>Kollektiv rəhbər</p>
              </div>
              {children.map((item, i) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 10 + "px",
                    }}
                  >
                    <div className={styles["entered-child"]}>
                      {item.surname} {item.name} {item.patronymic}
                    </div>
                    <button
                      onClick={() => deleteChild(i)}
                      className={styles["delete-btn"]}
                    >
                      Sil
                    </button>
                  </div>
                );
              })}
              <form action="" className={styles["form"]}>
                <div className={styles["double-radio"]}>
                  <div className={styles["search_container"]}>
                    <div style={{ display: 'flex' }}>
                      <select style={{ width: 46 + '%' }} className={styles["seriya"]} onChange={(e) => setSerialRadio(e.target.value)}>
                        <option value="1" selected={serialRadio === "1"}>AA</option>
                        <option value="2" selected={serialRadio === "2"}>AZE</option>
                      </select>
                      <input
                        type="number"
                        min="0"
                        inputmode="numeric"
                        name=""
                        id=""
                        placeholder="Seriya nömrəsi"
                        onChange={(e) => handleSerial(e)}
                        style={{ borderRadius: '0px 10px 10px 0px' }}
                        value={serialVal}
                        disabled={finRadio == 2 ? true : false}
                      />
                    </div>
                    <input
                      type="text"
                      name=""
                      id=""
                      style={{ width: 35 + '%' }}
                      placeholder="FİN kod"
                      disabled={finRadio == 1 ? false : true}
                      onChange={(e) => handleFin(e)}
                      value={finVal}
                    />

                    <div className={styles["search_buttons"]}>
                      <button
                        type="button"
                        className={styles["info-button"]}
                        onClick={() => setFinPopup(true)}
                      >
                        ?
                      </button>
                      <button
                        type="button"
                        className={styles["search"]}
                        onClick={() => setSearchFin()}
                      >
                        <img src={searchImg} alt="search" />
                      </button>
                    </div>
                  </div>

                  {serialError && <p className={styles["error"]}>{serialError}</p>}
                  {finError && <p className={styles["error"]}>{finError}</p>}
                </div>

                <div className={styles["input-photo"]}>
                  <div className={styles["two-two-input"]}>
                    <div className={styles["two-input"]}>
                      <label htmlFor="">İştirakçının adı</label>
                      <input
                        type="text"
                        placeholder="İştirakçının adı"
                        onChange={(e) => handleName(e)}
                        value={nameVal}
                        disabled={(studentFromAsan && !studentFromAsan?.name) ? false : disabled}
                      />
                      <label htmlFor="">Soyadı</label>
                      <input
                        type="text"
                        placeholder="Soyadı"
                        onChange={(e) => handleSurname(e)}
                        value={surnameVal}
                        disabled={(studentFromAsan && !studentFromAsan?.surname) ? false : disabled}
                      />
                      <p
                        className={styles["error-xl"]}
                        style={{ width: 200 + "px" }}
                      >
                        {nameError}
                      </p>
                    </div>
                    <div>
                      <label htmlFor="">Atasının adı</label>
                      <input
                        type="text"
                        placeholder="Atasının adı"
                        onChange={(e) => handlePatronymic(e)}
                        value={patronymicVal}
                        disabled={(studentFromAsan && !studentFromAsan?.patronymic) ? false : disabled}
                      />
                      <p
                        className={styles["error-sm"]}
                        style={{ width: 200 + "px" }}
                      >
                        {nameError}
                      </p>
                      <label htmlFor="">Doğum tarixi</label>
                      <DatePicker
                        disabled={(studentFromAsan && !studentFromAsan?.birth_date) ? false : disabled}
                        format={"YYYY-MM-DD"}
                        placeholder="il.ay.gün"
                        onChange={(e) => handleDate(e)}
                        value={dateVal}
                        className={styles["date_picker"]}
                      />
                      <p
                        className={styles["error"]}
                        style={{ width: 200 + "px" }}
                      >
                        {ageError}
                      </p>
                    </div>
                  </div>
                  <div className={styles["image-cont"]}>
                    <label
                      className={styles["image"]}
                      style={{
                        backgroundImage: asanImage ?
                          `url(data:image/png;base64,${asanImage}` :
                          `url(${imageLink}`
                      }}
                    >
                      <input
                        type="file"
                        accept="image"
                        onChange={(e) => handleImage(e)}
                        files={image}
                        disabled={imageType === 1}
                      />
                      {!image ? <p>Foto yüklə</p> : null}
                      {imageError && <p className={styles["error"]} style={{ position: "relative", top: 130, color: "red" }}>{imageError}</p>}
                    </label>
                  </div>
                </div>

                <div className={styles["inputs"]}>
                  <label htmlFor="">Cinsi</label>
                  <div
                    className={styles["db-radio-flex"]}
                    style={{ display: "flex" }}
                  >
                    <div className={styles["radio-label"]}>
                      <input
                        type="radio"
                        disabled={(studentFromAsan && !studentFromAsan?.gender) ? false : disabled}
                        checked={genderRadio == "MALE" ? true : false}
                        onClick={() => {
                          setGenderRadio("MALE");
                        }}
                      />
                      <p>Kişi</p>
                    </div>
                    <div className={styles["radio-label"]}>
                      <input
                        disabled={(studentFromAsan && !studentFromAsan?.gender) ? false : disabled}
                        type="radio"
                        checked={genderRadio == "MALE" ? false : true}
                        onClick={() => {
                          setGenderRadio("FEMALE");
                        }}
                      />
                      <p>Qadın</p>
                    </div>
                  </div>
                  <label htmlFor="">Qeydiyyat ünvanı</label>
                  <input
                    type="text"
                    placeholder="Qeydiyyat ünvanı"
                    onChange={(e) => handleRegAddress(e)}
                    disabled={(studentFromAsan && !studentFromAsan?.registration_address) ? false : disabled}
                    value={regAddress}
                  />
                  {regAddressError ? (
                    <p className={styles["error"]}>{regAddressError}</p>
                  ) : null}

                  <label htmlFor="">Faktiki yaşayış ünvanı</label>
                  <div className={styles["address"]}>
                    <div className={styles["cityy"]}>
                      <Select
                        placeholder="Şəhər/rayon"
                        size="large"
                        options={regionsSelect.map(item => ({
                          value: item.id,
                          label: item.name
                        }))}
                        value={city}
                        onChange={handleCity}
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        className={styles["region_select"]}
                      />
                    </div>

                    <div className={styles["fact"]}>
                      <Input
                        style={{ height: 43 }}
                        value={factAddress}
                        onChange={e => handleFactAddress(e)}
                        placeholder="Faktiki yaşayış ünvanı"
                      />
                    </div>
                  </div>
                  {factAddressError ? (
                    <p className={styles["error"]}>{factAddressError}</p>
                  ) : null}
                </div>
                <div className={styles["art-ed"]}>
                  <label>Xüsusi incəsənət təhsili</label>
                  <div style={{ display: "flex", gap: 100 + "px" }}>
                    <div style={{ display: "flex" }}>
                      <input
                        disabled={true}
                        type="radio"
                        checked={artSchool == 1 ? true : false}
                        onClick={() => {
                          setArtSchool(1);
                        }}
                      />
                      <span>Var</span>
                    </div>
                    <div style={{ display: "flex" }}>
                      <input
                        type="radio"
                        disabled={true}
                        checked={artSchool == 1 ? false : true}
                        onClick={() => {
                          setArtSchool(2);
                          setSpecialSchool("");
                        }}
                      />
                      <span>Yoxdur</span>
                    </div>
                  </div>
                </div>
                {artSchool == 1 ? (
                  <div className={styles["special-school"]}>
                    <label htmlFor="">
                      Xüsusi incəsənət təhsil müəssisəsi
                    </label>
                    <Select
                      disabled
                      value={artEducation}
                      style={{ width: "100%" }}
                      size="large"
                    />
                    {specialSchoolError ? (
                      <p style={{ color: "red", fontSize: 16 + "px" }}>
                        {specialSchoolError}
                      </p>
                    ) : null}
                  </div>
                ) :
                  <div className={styles["text-area"]} style={{ marginTop: 40 }}>
                    <label htmlFor="">Digər</label>
                    <textarea
                      onChange={(e) => handleArtSchoolDetails(e)}
                      id=""
                      name=""
                      rows="10"
                      placeholder="Xüsusi incəsənət təhsili (əgər varsa, detallar əlavə olunur)"
                      value={artSchoolDetails}
                    ></textarea>
                  </div>}
              </form>
              {childrenError ? (
                <p style={{ color: "red", fontSize: 16 + "px" }}>
                  {childrenError}
                </p>
              ) : null}
              <div className={styles["add-children"]}>
                <button
                  type="button"
                  className={styles["next"]}
                  onClick={() => handleMultiChildren()}
                >
                  İştirakçı əlavə et +
                </button>
              </div>
              {secondPageError ? (
                <>
                  <AgreementPopup
                    handleChange={setSecondPageError}
                    error={true}
                  >
                    {secondPageError}
                  </AgreementPopup>
                </>
              ) : null}
              <div className={styles["next-container"]}>
                <button
                  type="button"
                  className={styles["next"]}
                  onClick={() => handleInformation()}
                >
                  Növbəti
                </button>
              </div>
              {finPopup ? (
                <InfoPopup
                  handleChange={setFinPopup}
                  photos={[FinNew, FinAndSerialOld]}
                />
              ) : null}
              {serialPopup ? (
                <InfoPopup
                  handleChange={setSerialPopup}
                  photos={[SerialNew, FinAndSerialOld]}
                />
              ) : null}
              {birthCertPopup ? (
                <InfoPopup
                  handleChange={setBirthCertPopup}
                  photos={[BirthCertImg, ""]}
                  tabs={false}
                />
              ) : null}
            </div>
          </>
        ) : null}
        {page === 3 ? (
          <>
            <Wrapper>
              <div onClick={() => setPage(2)} className={styles["go-back"]}>
                <BackArrow />
                <p>Geri qayıt</p>
              </div>
            </Wrapper>
            <div className={styles["wrapper"]}>
              <h2 className={styles["reg"]}>Kollektiv qeydiyyat</h2>
              <div className={styles["progress"]}>
                <div
                  className={styles["progressed"]}
                  style={{ width: 100 + "%" }}
                ></div>
                <div className={styles["step1"]}>1</div>
                <div className={styles["step2-2"]}>2</div>
                <div className={styles["step3-3"]}>3</div>
              </div>
              <div className={styles["progress-name"]}>
                <p>Kollektiv məlumatlar</p>
                <p>Üzvləri daxil edin</p>
                <p>Valideyn və ya qanuni nümayəndə</p>
              </div>
              <form action="">
                <p className={styles["disclaimer"]}>
                  *Bu səhifə valideyn/qanuni nümayəndə tərəfindən
                  doldurulmalıdır. Nəzərə alınmalıdır ki, bütün məlumat bölgüsü
                  bu səhifədə göstərilən əlaqə vasitələri ilə paylaşılacaqdır.
                </p>
                <div
                  className={styles["double-radio"]}
                  style={{ marginTop: 10 }}
                >
                  <div>
                    <div className={styles["search_container"]}>
                      <div style={{ display: "flex" }}>
                        <select
                          style={{ width: 50 + "%" }}
                          className={styles["seriya"]}
                          onChange={(e) =>
                            setParentsSerialRadio(e.target.value)
                          }
                        >
                          <option
                            value="1"
                            selected={parentsSerialRadio === "1"}
                          >
                            AA
                          </option>
                          <option
                            value="2"
                            selected={parentsSerialRadio === "2"}
                          >
                            AZE
                          </option>
                        </select>
                        <input
                          type="number"
                          min="0"
                          inputmode="numeric"
                          name=""
                          id=""
                          placeholder="Seriya nömrəsi"
                          onChange={(e) => handleParentsSerial(e)}
                          value={parentsSerial}
                          style={{ borderRadius: "0px 10px 10px 0px" }}
                          disabled={finRadio == 2 ? true : false}
                        />
                      </div>
                      <input
                        type="text"
                        name=""
                        id=""
                        style={{ width: 30 + "%" }}
                        placeholder="FİN kod"
                        disabled={finRadio == 1 ? false : true}
                        onChange={(e) => handleParentsFin(e)}
                        value={parentsFin}
                      />

                      <div className={styles["search_buttons"]}>
                        <button
                          type="button"
                          className={styles["info-button"]}
                          onClick={() => setFinPopup(true)}
                        >
                          ?
                        </button>
                        <button
                          type="button"
                          className={styles["search"]}
                          onClick={() => searchParent()}
                        >
                          <img src={searchImg} alt="search" />
                        </button>
                      </div>
                    </div>
                    {parentsFinError ? (
                      <span className={styles["error"]}>{parentsFinError}</span>
                    ) : null}
                    <br />
                    {parentsSerialError ? (
                      <span className={styles["error"]}>
                        {parentsSerialError}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className={styles["parent_inputs_container"]}>
                  <div className={styles["parent_names_inputs"]}>
                    <label htmlFor="">Ad</label>
                    <input
                      type="text"
                      placeholder="Ad"
                      onChange={(e) => handleParentsName(e)}
                      value={parentsName}
                      disabled={(parentFromAsan && !parentFromAsan?.director_name) ? false : directorDisabled}
                    />
                  </div>
                  <div className={styles["parent_names_inputs"]}>
                    <label htmlFor="">Soyad</label>
                    <input
                      type="text"
                      placeholder="Soyad"
                      onChange={(e) => handleParentsSurname(e)}
                      value={parentsSurname}
                      disabled={(parentFromAsan && !parentFromAsan?.director_surname) ? false : directorDisabled}
                    />
                  </div>
                  <div className={styles["parent_names_inputs"]}>
                    <label htmlFor="">Ata adı</label>
                    <input
                      type="text"
                      placeholder="Ata adı"
                      onChange={(e) => handleParentsPatronymic(e)}
                      value={parentsPatronymic}
                      disabled={(parentFromAsan && !parentFromAsan?.director_patronymic) ? false : directorDisabled}
                    />
                  </div>
                </div>
                {parentsNameError ? (
                  <p className={styles["error"]}>{parentsNameError}</p>
                ) : null}

                <div className={styles["contact_inputs"]}>
                  <div>
                    <label>Əlaqə nömrəsi</label>
                    <div style={{ display: "flex" }}>
                      <select
                        style={{ width: 70 }}
                        className={styles["seriya"]}
                        onChange={(e) => handleParentsMobilePrefix(e, "first")}
                      >
                        {prefixes.map((prefix) => (
                          <option key={prefix.value}>{prefix.label}</option>
                        ))}
                      </select>
                      <input
                        type="number"
                        min="0"
                        inputmode="numeric"
                        placeholder="Nömrə"
                        onChange={(e) => handleParentsMobile(e, "first")}
                        value={parentsMobile.first?.number}
                        style={{ borderRadius: "0px 10px 10px 0px" }}
                        disabled={finRadio == 2}
                      />
                    </div>
                    {parentsMobileError?.first && (
                      <p className={styles["error"]}>
                        {parentsMobileError.first}
                      </p>
                    )}
                  </div>

                  <div>
                    <label>Əlaqə nömrəsi</label>
                    <div style={{ display: "flex" }}>
                      <select
                        style={{ width: 70 }}
                        className={styles["seriya"]}
                        onChange={(e) => handleParentsMobilePrefix(e, "second")}
                      >
                        {prefixes.map((prefix) => (
                          <option key={prefix.value}>{prefix.label}</option>
                        ))}
                      </select>
                      <input
                        type="number"
                        min="0"
                        inputmode="numeric"
                        placeholder="Nömrə"
                        onChange={(e) => handleParentsMobile(e, "second")}
                        value={parentsMobile.second?.number}
                        style={{ borderRadius: "0px 10px 10px 0px" }}
                        disabled={finRadio == 2}
                      />
                    </div>
                    {parentsMobileError?.second && (
                      <p className={styles["error"]}>
                        {parentsMobileError.second}
                      </p>
                    )}
                  </div>
                </div>

                <div style={{ marginBottom: 20 }}>
                  <label htmlFor="">Email</label>
                  <div>
                    <input
                      type="email"
                      placeholder="Email"
                      onChange={(e) => handleParentsEmail(e)}
                      value={parentsEmail}
                      style={{ boxSizing: "border-box", width: "100%" }}
                    />
                  </div>
                  {parentsEmailError && (
                    <p className={styles["error"]}>{parentsEmailError}</p>
                  )}
                </div>

                {/* {parentsMobileError ? (
                  <p className={styles["error"]}>{parentsMobileError}</p>
                ) : null} */}
                <div className={styles["checkboxes"]}>
                  <div>
                    <label
                      htmlFor=""
                      onClick={() => setAgreementPopup(true)}
                      style={{ cursor: "pointer", color: "blue" }}
                    >
                      İştirak şərtləri və razılıq mətni
                    </label>
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      onChange={() => handleAgreement()}
                      checked={agreement}
                    />
                  </div>
                  {agreementPopup ? (
                    <AgreementPopup handleChange={setAgreementPopup}>
                      Qeydiyyat üçün tərəfimdən təqdim olunan məlumatların
                      doğruluğunu, həmçinin festivalın keçirilmə qaydaları və
                      şərtləri ilə tanış olduğumu təsdiq edirəm. Festival
                      iştirakçısının iştirakı çərçivəsində çəkilən foto və
                      videomateriallarının ictimailəşdirilməsinə etiraz etmirəm
                      və məlumatların düzgünlüyünə dair şəxsən məsuliyyət
                      daşıyıram.
                    </AgreementPopup>
                  ) : null}

                  {thirdPageError ? (
                    <>
                      <AgreementPopup
                        handleChange={setThirdPageError}
                        error={true}
                      >
                        {thirdPageError}
                      </AgreementPopup>
                    </>
                  ) : null}
                </div>
                <div className={styles["next-container"]}>
                  <button
                    type="button"
                    className={styles["next"]}
                    onClick={() => {
                      handleInformation();
                    }}
                    disabled={!agreement}
                  >
                    Qeydiyyatı təsdiq edirəm
                  </button>
                </div>
              </form>
              {finPopup ? (
                <InfoPopup
                  handleChange={setFinPopup}
                  photos={[FinNew, FinAndSerialOld]}
                />
              ) : null}
              {serialPopup ? (
                <InfoPopup
                  handleChange={setSerialPopup}
                  photos={[SerialNew, FinAndSerialOld]}
                />
              ) : null}
            </div>
          </>
        ) : null}
        {page === 4 ? (
          <>
            {postResponse ? (
              <>
                <Wrapper>
                  <Link to={"/"} className={styles["go-back"]}>
                    <BackArrow />
                    <p>Geri qayıt</p>
                  </Link>
                </Wrapper>
                <Wrapper>
                  <div className={styles["done-container"]}>
                    <div className={styles["done"]}>
                      <img src={Done} alt="done" />
                      <h3>
                        Qeydiyyat uğurla tamamlanmışdır. Kollektivin qeydiyyat
                        kodu:
                      </h3>
                      <h2 style={{ fontWeight: 900 }}>{code}</h2>
                      <button
                        className={styles["copy_button"]}
                        onClick={() => navigator.clipboard.writeText(code)}
                      >
                        Kodu kopyala
                      </button>
                      <h3>
                        Kollektivin kodu festival çərçivəsində dəyişilməz
                        olaraq qalacaqdır. Xahiş edirik kodu itirməyəsiniz.
                      </h3>
                    </div>
                  </div>
                </Wrapper>
              </>
            ) : (
              <Loading />
            )}
          </>
        ) : null}
      </section>
    </>
  );
};
export default CollectiveRegistrationTest;
