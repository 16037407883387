import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

export const imesApi = axios.create({
  baseURL: process.env.REACT_APP_IMES_API
})

export default instance;