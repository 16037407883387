import React, { useEffect, useState } from "react";
// import CultureMinistryLogo from "../../assets/images/culture_ministry_logo.svg";
import CultureMinistryLogo from "../../assets/images/mn_logo.png";
import HeydarAliyevCenterLogo from "../../assets/images/heydar_aliyev_center_logo.svg";
import EducationMinistryLogo from "../../assets/images/education_ministry_logo.svg";
import KidsArtFestLogo from "../../assets/images/kids_art_fest_logo.svg";
import Logo from "../../assets/images/kids_art_fest_logo_3.png";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import styles from "./index.module.css";
import registrationCheck from "../../api/registrationCheck";
import Loading from "../../components/loading";

const RegistrationCheckDetails = () => {
  // const [name, setName] = useState(null);
  // const [code, setCode] = useState(null);
  // const [nomination, setNomination] = useState(null);
  // const [city, setCity] = useState(null);
  // const [day, setDay] = useState(null);
  // const [time, setTime] = useState(null);
  // const [location, setLocation] = useState(null);
  // const [number, setNumber] = useState(null);
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(true);

  const pdfHandler = () => {
    const divToDisplay = document.getElementById("pdf");
    html2canvas(divToDisplay).then(function (canvas) {
      const divImage = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        unit: "px",
        format: [canvas.width, canvas.height],
        compress: true,
      });
      pdf.addImage(divImage, "PNG", 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height);
      pdf.save(`${data?.ad_soyad}.pdf`);
    });
  };
  useEffect(() => {
    // const userData = JSON.parse(sessionStorage.userData);
    // async function fetchData() {
    //   const { data } = await registrationCheck.getTimePlace(userData);
    //   setData(data);
    //   setLoading(true);
    // }
    // fetchData();
  }, []);
  return (
    <>
      {loading ? (
        <>
          <div className={styles["wrapper"]} id="pdf">
            <div className={styles["inner-container"]}>
              <header className={styles["header"]}>
                <div className={styles["logo"]}>
                  {/* <h1>
                    Ümummilli lider Heydər Əliyevin <br />
                    100 illiyinə həsr edilən <br />
                    Uşaq İncəsənət Festivalı
                  </h1> */}
                  {/* <div> */}
                    <img src={Logo} alt="" />
                  {/* </div> */}
                </div>
                <div className={styles["partners"]}>
                  <img src={CultureMinistryLogo} alt="" />
                  <img src={HeydarAliyevCenterLogo} alt="" />
                  <img src={EducationMinistryLogo} alt="" />
                </div>
              </header>
              <h2 className={styles["title"]}>
                İştirak məlumatları və qaydaları
              </h2>
              <main className={styles["main"]}>
                <div>
                  <p>Ad, Soyad</p>
                  {/* <p>{data?.ad_soyad}</p> */}
                  <p>AYAN	KƏRİMOVA</p>
                </div>
                <div>
                  <p>İştirakçı kodu</p>
                  {/* <p>{data?.kod}</p> */}
                  <p>20251005221</p>
                </div>
                <div>
                  <p>Qeydiyyatdan keçdiyi rayon/şəhər</p>
                  {/* <p>{data?.rayon}</p> */}
                  <p>Xaçmaz</p>
                </div>
                <div>
                  <p>Nominasiya</p>
                  {/* <p>{data?.nominasiya}</p> */}
                  <p>Piano ifaçılığı</p>
                </div>
                <div>
                  <p>Müsabiqənin keçiriləcəyi gün</p>
                  {/* <p>{data?.gun}</p> */}
                  <p>10.04.2025</p>
                </div>
                <div>
                  <p>Vaxt</p>
                  {/* <p>{data?.vaxt}</p> */}
                  <p>15:00</p>
                </div>
                <div>
                  <p>Məkan</p>
                  {data?.mekan ? (
                    <>
                      <div className={styles["text-right-div"]}>
                        <p>{data?.mekan[0]}</p>
                        <p>{data?.mekan[1]}</p>
                      </div>
                    </>
                  ) : (
                    <p>Sumqayıt, Kimyaçılar Mədəniyyət Sarayı</p>
                  )}
                </div>
                <div>
                  <p>Məlumat üçün əlaqə nömrəsi 1</p>
                  {/* <p>{data?.nomre}</p> */}
                  <p>055 5555555</p>
                </div>
                <div>
                  <p>Məlumat üçün əlaqə nömrəsi 2</p>
                  {/* <p>{data?.nomre}</p> */}
                  <p>070 7777777</p>
                </div>
              </main>
              <footer>
                <h3 className={styles["h3"]}>İştirak qaydaları:</h3>
                <ol className={styles["ol"]}>
                  <li>
                    İştirakçılar müsabiqə vaxtından 15 dəqiqə əvvəl müsabiqə
                    məkanına gəlməlidirlər.
                  </li>
                  <li>
                    İştirakçılar müsabiqə məkanına gəldikdə özləri ilə bu
                    formanı kağız və ya elektron formatda, eləcə də
                    şəxsiyyətlərini təsdiq edən sənədi (şəxsiyyət vəsiqəsi və ya
                    doğum haqqında şəhadatnamə) gətirməlidirlər.
                  </li>
                  <li>
                    Müsabiqədə iştirak çərçivəsində, həmçinin gözləmə zamanı
                    ümumi etik qaydalara riayət olunmalıdır. Müsabiqə keçirilən
                    məkanda digər iştirakçılara maneçilik törətməmək məqsədilə
                    yüksək səslə danışmaq, gülmək, məşq etmək qadağandır.
                  </li>
                </ol>
              </footer>
            </div>
          </div>
          <div className={styles["download-container"]}>
            {/* <h3>
            Xahiş edirik aşağıdakı "İstirakçi məlumat vərəqəsini" yükləyərək
            iştirak qaydalarına dair ətraflı məlumatlarla taniş olasınız:
          </h3> */}
            <div className={styles["next-container"]}>
              <button
                type="button"
                className={styles["next"]}
                onClick={() => pdfHandler()}
              >
                Faylı yüklə
              </button>
            </div>
            {/* <div className={styles["inputs"]}>
              <div className={styles["email"]}>
                <label htmlFor="">Faylı emailə göndər:</label>
                <input type="email" placeholder="E-mail" />
                <button
                  type="button"
                  className={styles["next"]}
                  style={{ paddingRight: 3 + "px", paddingLeft: 3 + "px" }}
                >
                  Göndər
                </button>
              </div>
            </div> */}
          </div>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default RegistrationCheckDetails;
