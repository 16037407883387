import axios from "../index";

const search = (query) => {
  return axios.get(`search?type=${query.type}&document=${query.document}&value=${query.value}`);
}

const checkPersonal = (query) => {
  return axios.get(`check-personal?type=${query.type}&document=${query.document}&value=${query.value}`)
}

const checkCollective = (query) => {
  return axios.get(`check-collective?type=${query.type}&document=${query.document}&value=${query.value}`)
}

const getTimePlace = data => {
  return axios.post('time-place', data, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  })
}

export default { search, getTimePlace, checkPersonal, checkCollective }