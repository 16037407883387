import axios from "../index";

const sendFirstPage = data => {
  return axios.post('personal-registration-first-step', data, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  })
}

const sendSecondPage = data => {
  return axios.post('personal-registration-second-step', data)
}

const sendThirdPage = data => {
  return axios.post('personal-registration-third-step', data)
}

export default { sendFirstPage, sendSecondPage, sendThirdPage }