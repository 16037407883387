import React from "react";
import styles from "./index.module.css";
import { BackArrow, PersonalIcon, CollectiveIcon } from "../../icons";
import Wrapper from "../../components/UI/wrapper";
import { Link } from "react-router-dom";
const TestRegistration = () => {
  return (
    <Wrapper>
      <Link to={"/"} className={styles["go-back"]}>
        <BackArrow />
        <p>Geri qayıt</p>
      </Link>
      <div className={styles["fl"]}>
        <div className={styles["container"]}>
          <Link to={"/test-registration"}>
            <PersonalIcon />
            <p>Fərdi</p>
          </Link>
          <Link to={"/test-collective"}>
            <CollectiveIcon />
            <p>Kollektiv</p>
          </Link>
        </div>
        {/* <h2 className={styles["announcement"]}>
          Hörmətli iştirakçılar! Birinci Uşaq İncəsənət Festivalına qeydiyyat 30
          Mart 2023 cü il tarixində başa çatmışdır. Növbəti mərhələ ilə bağlı
          məlumatlar yaxın günlərdə kütləvi informasiya vasitələri və festivalın
          rəsmi sosial media səhifələrində paylaşılacaqdır.
        </h2>
        <Link to={"/"} className={styles["return"]}>
          Ana səhifəyə geri dön
        </Link> */}
      </div>
    </Wrapper>
  );
};

export default TestRegistration;
